import React, { useEffect } from "react";

const ErrorToast = (props) => {
    const { setErrorMsg, errorMsg } = props;
    useEffect(() => {
        setTimeout(() => {
            setErrorMsg("")
        }, 10000)
    }, [errorMsg])
    return <>
        {errorMsg != "" ?
            <div className="align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className="toast-body">
                        {errorMsg}
                    </div>
                </div>
            </div>
            : <></>
        }
    </>
}

export default ErrorToast;