import React from 'react';
import { Modal } from 'react-bootstrap';

const NewPlan = (props) => {
	const { addCard, setAddCard, handleAddFormChange, handleAddFormSubmit } = props;
	return (
		<>
			<Modal className="modal fade bd-example-modal-lg" show={addCard} onHide={() => setAddCard(false)} centered size="lg">
				<div role="document">
					<div className="">
						<form>
							<div className="modal-header">
								<h4 className="modal-title fs-20">Add New Plan</h4>
								<button type="button" className="btn-close" onClick={() => setAddCard(false)} data-dismiss="modal">
									<span></span>
								</button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Plan Name</label>
													<div className="plan_name">
														<input
															type="text"
															className="form-control"
															autoComplete="off"
															name="plan_name"
															required="required"
															onChange={handleAddFormChange}
															placeholder="Enter Plan Name"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
												<div class="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Validity</label>
													<div className="contact-name">
														<input
															type="number"
															className="form-control"
															autoComplete="off"
															name="plan_days"
															required="required"
															onChange={handleAddFormChange}
															placeholder="plan days"
														/>
														<span className="validation-text"></span>
													</div>
												</div>

											</div>
										</div>

										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Currency Code</label>
													<select className='form-control custom-react-select mb-3 mb-xxl-0' name="currency_code">
														<option value="INR">INR</option>
														<option value="GBP">GBP</option>
														<option value="USD">USD</option>

													</select>
												</div>
												<div class="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Plan Price</label>
													<input
														type="number"
														className="form-control"
														autoComplete="off"
														name="plan_price"
														required="required"
														onChange={handleAddFormChange}
														placeholder="plan Price"
													/>
												</div>
											</div>
										</div>


										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Jobs limit</label>
													<div className="contact-name">
														<input
															type="number"
															className="form-control"
															autoComplete="off"
															name="jobs_limit"
															required="required"
															onChange={handleAddFormChange}
															placeholder="jobs limit"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
												<div class="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Sub Recruiter Limit</label>
													<div className="contact-name">
														<input
															type="number"
															className="form-control"
															autoComplete="off"
															name="sub_recruiter_limit"
															required="required"
															onChange={handleAddFormChange}
															placeholder="sub recruiter limit"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>

										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-4">
													<label className="text-black font-w500">Total Unlock Quota</label>
													<div className="contact-name">
														<input
															type="number"
															className="form-control"
															autoComplete="off"
															name="total_unlock_quota"
															required="required"
															onChange={handleAddFormChange}
															placeholder="Total Unlock Quota"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
												<div class="form-group mb-3 col-md-4">
													<label className="text-black font-w500">General Unlock Quota</label>
													<div className="contact-name">
														<input
															type="number"
															className="form-control"
															autoComplete="off"
															name="general_unlock_quota"
															required="required"
															onChange={handleAddFormChange}
															placeholder="General Unlock Quota"
														/>
														<span className="validation-text"></span>
													</div>
												</div>

												<div class="form-group mb-3 col-md-4">
													<label className="text-black font-w500">Applicants Unlock Quota</label>
													<div className="contact-name">
														<input
															type="number"
															className="form-control"
															autoComplete="off"
															name="applicants_unlock_quota"
															required="required"
															onChange={handleAddFormChange}
															placeholder="Applicants Unlock Quota"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>

										<div className="form-group mb-3">
											<label className="text-black font-w500">Status</label>
											<div className="contact-name">
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="1"
													id="statusActive"
													checked={true}
													onChange={handleAddFormChange}
												/>
												<label className="form-check-label" htmlFor="statusActive">
													Active &nbsp;&nbsp;&nbsp;
												</label>
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="0"
													id="statusInactive"
													onChange={handleAddFormChange}
												/>
												<label className="form-check-label" htmlFor="statusInactive">
													InActive
												</label>
												<span className="validation-text"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-success" onClick={handleAddFormSubmit}>
									Add
								</button>
								<button type="button" onClick={() => setAddCard(false)} className="btn btn-danger">
									<i className="flaticon-delete-1"></i> Discard
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default NewPlan;