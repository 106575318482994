import React,{useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Dropdown} from 'react-bootstrap';

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import VacanyTab from '../Jobick/Home/VacanyTab';
import CanvasChartTab from '../Jobick/Home/CanvasChartTab';
import FeaturedCompanies from '../Jobick/Home/FeaturedCompanies';
import RecentActivity from '../Jobick/Home/RecentActivity';
import HomeSlider from '../Jobick/Home/HomeSlider';
import {dashboardCandidateAPI} from "../../api/dashboard";
import DashboardJobs from "./DashboardJobs";
import DashboardRecruiter from "./DashboardRecruiter";

//import FinancialChartMultipleData from './FinancialChartMultipleData';

//Images
import pic1 from './../../../images/profile/pic1.jpg';

//Components
const NewCustomers1 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers1"), 1000)
);
const NewCustomers2 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers2"), 1000)
);
const NewCustomers3 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers3"), 1000)
);
const NewCustomers4 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers4"), 1000)
);
const PieChartDashboard = loadable(() =>
	pMinDelay(import("./pieChartDashboard"), 1000)
);


const Home = () => {	
	const { changeBackground, background } = useContext(ThemeContext);
	const [appl, setApp]= useState({});
	  useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
		fetchApplicationData();
	}, []);
	
	const [control3, setControl3] = useState('Newest');
	const fetchApplicationData = async()=>{
		let d = await dashboardCandidateAPI();
		//console.log("Application Data", d, );
        setApp(d);
	}
	return(
		<>
			<div className="row">
				<div className="col-xl-6">
				
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
								<h5 class="card-title">All Applicants</h5>
								<hr/>
									<div className="row separate-row">
										<div className="col-sm-6">
											<div className="job-icon pb-4 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.total_candidate}</h2>
														<i className={`fa-solid ms-3 ${appl?.total_candidate_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Total Applications</span>
												</div>	
												
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.today_candidate}</h2>
														<i className={`fa-solid ms-3 ${appl?.today_candidate_flag}`}></i>

													</div>	
													<span className="fs-14 d-block mb-2">Applications Today</span>
												</div>	
											</div>
										</div>
										<div className="col-sm-6" style={{"border-bottom": "1px solid #eee"}}>
											<div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.active_candidate}</h2>
														<i className={`fa-solid ms-3 ${appl?.active_candidate_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Active Candidates</span>
												</div>	
											</div>
										</div>
										<div className="col-sm-6" style={{"border-bottom": "1px solid #eee"}}>
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.lgbt_unverified}</h2>
														<i className={`fa-solid ms-3 ${appl?.lgbt_unverified_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">LGBT+ UNVERIFIED</span>
												</div>	
											</div>
										</div>

										<div className="col-sm-6" >
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.no_resume}</h2>
														<i className={`fa-solid ms-3 ${appl?.no_resume_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">No Resume</span>
												</div>													
											</div>
										</div>

										<div className="col-sm-6">
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.applicants_from}</h2>
														<i className={`fa-solid ms-3 ${appl?.applicants_from_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Applicants from</span>
												</div>	
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<DashboardRecruiter />
						<DashboardJobs/>						
					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">

						<PieChartDashboard  type="Skills"/>
						<PieChartDashboard  type="Locations"/>
						
					</div>
				</div>
			</div>	
		</>
	)
}
export default Home;