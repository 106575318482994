import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import * as XLSX from "xlsx"; // Import for Excel download
import { recruiterListAPI, deleteRecruiter } from "../../api/recruiter";
import NewR from "./NewR";
import EditR from "./EditR";
import _ from "lodash";

const RLists = () => {
  const [addCard, setAddCard] = useState(false);
  const [Events, setEvents] = useState([]);
  const [recruiterType] = useState([
    { id: 1, name: "Regular Recruiter" },
    { id: 2, name: "Internship Recruiter" },
  ]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "none",
  });
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page
  const [hasMore, setHasMore] = useState(true);
  const [totalEntries, setTotalEntries] = useState(0);
  const navigate = useNavigate();

  // Debounced Filter Handler
  const debouncedFilter = useMemo(() => {
    setPage(0);
    return _.debounce(setFilter, 500);
  }, []);

  useEffect(() => {
    dataFetch();
  }, [sortConfig, page]);

  useEffect(() => {
    setPage(1);
    dataFetch();
  }, [filter, rowsPerPage]);

  const dataFetch = async () => {
    try {
      let params = {
        page,
        limit: rowsPerPage,
        sort_by: sortConfig.key,
        sort_direction: sortConfig.direction,
        keyword: filter,
      };
      const response = await recruiterListAPI(params);
      setTotalEntries(response.total || 1);
      setHasMore(response.data.length >= rowsPerPage);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setHasMore(false);
    }
  };

  const handleCopy = () => {
    const text = Events.map((job) => Object.values(job).join(", ")).join("\n");
    navigator.clipboard.writeText(text).then(() => {
      swal("Copied!", "Table data copied to clipboard.", "success");
    });
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(Events);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Jobs");
    XLSX.writeFile(workbook, "RecuiterList.xlsx");
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1); // Reset to first page on rows change
    // dataFetch(true);
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    setSortConfig({ key, direction });
    setPage(1);
    setHasMore(true);
  };

  // Delete data
  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      let res = await deleteRecruiter(contactId);
      if (res?.error) {
        swal("Oops", res.error, "error");
      } else {
        dataFetch();
        swal("Deleted!", "Record has been deleted!", "success");
      }
    }
  };

  // Edit start
  const [editModal, setEditModal] = useState(false);
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = async (id) => {
    setEditContactId(id);
    setEditModal(true);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <span>▲</span>;
      } else if (sortConfig.direction === "desc") {
        return <span>▼</span>;
      }
    }
    return <span>↕</span>;
  };


  const handleJobsClick = (e, recruiter_id) => {
    e.preventDefault();
    localStorage.setItem("recruiter_id", recruiter_id);
    localStorage.removeItem("subrecruiter_id");
    navigate("/job_list?type=recruiter");
  };

  const handleSubRClick = (e, recruiter_id) => {
    e.preventDefault();
    localStorage.setItem("rid", recruiter_id);
    navigate("/sub_recruiter_list");
  };

  const renderPagination = () => (
    <div className="d-md-flex d-block align-items-center justify-content-between text-center flex-wrap mt-md-0 mt-3">
      <div className="mb-md-0 mb-2">
        <h5 className="mb-0">
          Showing {Math.min((page - 1) * rowsPerPage + 1, totalEntries)} to{" "}
          {Math.min(page * rowsPerPage, totalEntries)} of {totalEntries} entries
        </h5>
      </div>
      <nav>
        <ul className="pagination pagination-circle justify-content-center">
          <li className="page-item page-indicator">
            <Link
              to="#"
              className="page-link"
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              disabled={page === 1}
            >
              <i className="fa fa-angle-double-left" />
            </Link>
          </li>
          <li className="page-item active">
            <Link to="#" className="page-link">
              {page}
            </Link>
          </li>
          <li className="page-item page-indicator">
            <Link
              to="#"
              className="page-link"
              onClick={() => setPage((prev) => (hasMore ? prev + 1 : prev))}
              disabled={!hasMore}
            >
              <i className="fa fa-angle-double-right" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Recruiter List</h4>
        <div className="d-flex">
          <Link
            to={"#"}
            className="btn btn-primary btn-sm"
            onClick={() => setAddCard(true)}
          >
            <i className="fas fa-plus me-2"></i>Add New Recruiter
          </Link>
        </div>
        <NewR addCard={addCard} setAddCard={setAddCard} dataFetch={dataFetch} />
        <EditR
          editModal={editModal}
          setEditModal={setEditModal}
          dataFetch={dataFetch}
          editid={editContactId}
          setEditContactId={setEditContactId}
        />
      </div>
      <div className="row mb-4">
        <div className="col-md-6 ms-auto">
          <input
            type="text"
            placeholder="Filter by keyword"
            className="form-control me-3"
            onChange={(e) => debouncedFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-3">
              <button className="btn btn-secondary me-3" onClick={handleCopy}>
                COPY
              </button>
              <button className="btn btn-primary" onClick={handleDownloadExcel}>
                Download Excel
              </button>
            </div>
            <div className="col-xl-2">
              <select
                className="form-select w-100 me-3"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                {[10, 25, 50, 100, 1000, 2000].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>R.ID</th>
                  <th onClick={() => handleSort("name")}>
                    Name {renderSortIcon("name")}
                  </th>
                  <th onClick={() => handleSort("company_name")}>
                    Company {renderSortIcon("company_name")}
                  </th>
                  <th>Email</th>
                  <th onClick={() => handleSort("recruiter_type")}>
                    Type {renderSortIcon("recruiter_type")}
                  </th>
                  <th onClick={() => handleSort("created_at")}>
                    Created Time {renderSortIcon("created_at")}
                  </th>

                  <th onClick={() => handleSort("created_by_name")}>
                    Created By {renderSortIcon("created_by_name")}
                  </th>
                  <th onClick={() => handleSort("plan_expire_date")}>
                    Until {renderSortIcon("plan_expire_date")}
                  </th>
                  <th onClick={() => handleSort("total_unlock_quota")}>
                    Total Unlock Quota {renderSortIcon("total_unlock_quota")}
                  </th>
                  <th onClick={() => handleSort("total_unlocked_remaining")}>
                    Remaining Total Unlock Quota{" "}
                    {renderSortIcon("total_unlocked_remaining")}
                  </th>
                  <th onClick={() => handleSort("general_unlock_quota")}>
                    General Unlock Quota{" "}
                    {renderSortIcon("general_unlock_quota")}
                  </th>
                  <th onClick={() => handleSort("general_unlocked_remaining")}>
                    General Unlocked{" "}
                    {renderSortIcon("general_unlocked_remaining")}
                  </th>
                  <th onClick={() => handleSort("applicants_unlock_quota")}>
                    Applicants Unlock Quota{" "}
                    {renderSortIcon("applicants_unlock_quota")}
                  </th>
                  <th
                    onClick={() => handleSort("applicants_unlocked_remaining")}
                  >
                    Applicants Unlocked Remaining Jobs{" "}
                    {renderSortIcon("applicants_unlocked_remaining")}
                  </th>
                  <th onClick={() => handleSort("posted_jobs_remaining")}>
                    Posted Jobs {renderSortIcon("posted_jobs_remaining")}
                  </th>
                  <th onClick={() => handleSort("sub_recruiter_quota")}>
                    Sub Recruiter Quota {renderSortIcon("sub_recruiter_quota")}
                  </th>
                  <th
                    onClick={() => handleSort("subrecruiter_remaining_quota")}
                  >
                    Sub Recruiter Remaining Quota{" "}
                    {renderSortIcon("subrecruiter_remaining_quota")}
                  </th>
                  <th onClick={() => handleSort("plan_start_date")}>
                    Plan Start Date {renderSortIcon("plan_start_date")}
                  </th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Events?.length > 0 ? (
                  Events?.map((Event, index) => (
                    <tr key={index}>
                      <td>{Event.id}</td>
                      <td>{Event?.name}</td>
                      <td>{Event?.company_name}</td>
                      <td>{Event.email}</td>
                      <td>
                        {Event.recruiter_type
                          ?.split(",")
                          .map((it) => recruiterType[it - 1].name)
                          .join(", ")}
                      </td>
                      <td>{Event.created_at}</td>
                      <td>{Event.created_by_name}</td>
                      <td>{Event.plan_expire_date}</td>
                      <td>{Event.total_unlock_quota}</td>
                      <td>{Event.total_unlocked_remaining}</td>
                      <td>{Event.general_unlock_quota}</td>
                      <td>{Event.general_unlocked_remaining}</td>
                      <td>{Event.applicants_unlock_quota}</td>
                      <td>{Event.applicants_unlocked_remaining}</td>
                      <td>{Event.posted_jobs_remaining}</td>
                      <td>{Event.sub_recruiter_quota}</td>
                      <td>{Event.subrecruiter_remaining_quota}</td>
                      <td>{Event.plan_start_date}</td>
                      <td>
                        <span
                          className={`badge badge-lg light badge-${
                            Event.published == "1" ? "success" : "danger"
                          }`}
                        >
                          {Event.published == "1" ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <div title="Manage Sub Recruiter" className="mr-2">
                            <Link
                              to={"#"}
                              onClick={(e) => handleSubRClick(e, Event.id)}
                              className="btn btn-secondary light mr-2"
                            >
                              MSR
                            </Link>
                          </div>
                          <div title="View Jobs" className="mr-2">
                            <Link
                              to={"#"}
                              className="btn btn-secondary light mr-2"
                              onClick={(e) => handleJobsClick(e, Event.id)}
                            >
                              View Jobs
                            </Link>
                          </div>

                          <div className="action-buttons d-flex justify-content-end">
                            <Link
                              to={"#"}
                              className="btn btn-secondary light mr-2"
                              onClick={() => handleEditClick(Event.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                  ></path>
                                  <rect
                                    fill="#000000"
                                    opacity="0.3"
                                    x="5"
                                    y="20"
                                    width="15"
                                    height="2"
                                    rx="1"
                                  ></rect>
                                </g>
                              </svg>
                            </Link>
                            <Link
                              to={"#"}
                              className="btn btn-danger light"
                              onClick={() => handleDeleteClick(Event.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="100%" className="text-center">
                        {" "}
                        No Record Found.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {renderPagination()}
    </>
  );
};

export default RLists;
