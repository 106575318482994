import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { skillsAPI, locationsAPI, exprienceAPI, identityAPI } from "../../api/skillm";
import SkillMatrix from "./SkillMatrix";
import LocationMatrix from "./Locations";
import ExperienceMatrix from "./Experience";
import IdentityMatrix from "./Identity";

const SkillMLists = () => {
	const [type, setType] = useState('s');
	const [data, setData] = useState([]);

	useEffect(() => {
		dataFetch();
	}, [type]);

	const dataFetch = async () => {
		switch (type) {
			case "s":
				let data = await skillsAPI();
				setData(data);
				break;
			case "l":
				let lo = await locationsAPI();
				setData(lo);
				break;
			case "e":
				let ex = await exprienceAPI();
				setData(ex);
				break;
			case "i":
				let ide = await identityAPI();
				setData(ide);
				break;
			default:
				setData([]);
				break;
		}
	};

	const updateType = (e, t) => {
		e.preventDefault();
		setType(t);
	};

	return (
		<>
			<div className="d-flex align-items-center mb-4 flex-wrap">
				<h4 className="fs-20 font-w600 me-auto">Skill matrix List</h4>
			</div>
			<div className="d-flex justify-content-end mb-3">
				<Link to={"#"} className="btn btn-primary me-2 btn-sm" onClick={(e) => updateType(e, "s")}>
					Skill
				</Link>
				<Link to={"#"} className="btn btn-primary me-2 btn-sm" onClick={(e) => updateType(e, "l")}>
					Locations
				</Link>
				<Link to={"#"} className="btn btn-primary me-2 btn-sm" onClick={(e) => updateType(e, "e")}>
					Experiences
				</Link>
				<Link to={"#"} className="btn btn-primary btn-sm" onClick={(e) => updateType(e, "i")}>
					Identity
				</Link>
			</div>

			{type === "s" && <SkillMatrix data={data?.data} />}
			{type === "l" && <LocationMatrix data={data?.data} locations={data?.locations} />}
			{type === "e" && <ExperienceMatrix data={data?.data} />}
			{type === "i" && <IdentityMatrix data={data?.data} />}
		</>
	);
};

export default SkillMLists;
