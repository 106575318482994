import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const dashboardCandidateAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/dashboard/candidates`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching admin/dashboard/candidates:", error);
      }
}

export const dashboardJobsAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/dashboard/jobs`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching admin/dashboard/jobs:", error);
      }
}

export const dashboardRecuiterAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/dashboard/recruiters`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching admin/dashboard/jobs:", error);
      }
}

export const dashboardSkillsAPI=async()=>{
  try {
      let config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/dashboard/skills`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching admin/dashboard/skills:", error);
    }
}

export const dashboardLocationsAPI=async()=>{
  try {
      let config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/dashboard/locations`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching admin/dashboard/locations:", error);
    }
}





