import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import * as XLSX from "xlsx"; // Import for Excel download
import { candidateListAPI, deleteCandidate } from "../../api/candidate";
import { getAllSkills, getAllIdentity, getAllCities } from "../../api/common";
import useDebounce from "../../hooks/useDebounce";
import useAccess from "../../api/useAccess";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const CandidateLists = () => {
  const query = useQuery();
  const paramValue = query.get("type");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page
  const [hasMore, setHasMore] = useState(true);
  const [totalEntries, setTotalEntries] = useState(0);
  const [identifyAsFilter, setIdentifyAsFilter] = useState("");
  const [skillsFilter, setSkillsFilter] = useState(""); 
  const [cityFilter, setCityFilter] = useState("");
  const [candidateStatusFilter, setCandidateStatusFilter] = useState("");
  const [keywordFilter, setKeywordFilter] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "DESC",
  });
  const [skillList, setSkillList] = useState([]);
  const [identityList, setIdentityList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const { fetchAccess } = useAccess();
  const permission = fetchAccess();
  var job_id = "";
  var event_id = "";
  if (paramValue == "event") {
    job_id = "";
    event_id = localStorage.getItem("event_id")
      ? localStorage.getItem("event_id")
      : "";
  } else if (paramValue == "job") {
    job_id = localStorage.getItem("job_id")
      ? localStorage.getItem("job_id")
      : "";
    event_id = "";
  } else {
    job_id = "";
    event_id = "";
  }

  const debouncedKeywordFilter = useDebounce(keywordFilter, 500);
  const navigate = useNavigate();
  const observer = useRef();

  const dataFetch = async (reset = false) => {
    try{
    let params = {
      page,
      limit: rowsPerPage,
      identify_as: identifyAsFilter,
      skills: skillsFilter,
      sort_by: sortConfig.key,
      sort_direction: sortConfig.direction,
      city: cityFilter,
      candidate_status: candidateStatusFilter,
      keyword: debouncedKeywordFilter,
      job_id: job_id,
      event_id: event_id,
    };

    const response = await candidateListAPI(params);
      setTotalEntries(response.total || 1);
      setHasMore(response.data.length >= rowsPerPage);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setHasMore(false);
    }
  };

  useEffect(() => {
    setSkillIdentity();
  }, []);

  const setSkillIdentity = async () => {
    let s = await getAllSkills();
    let ident = await getAllIdentity();
    let c = await getAllCities();
    setSkillList(s);
    setIdentityList(ident);
    setCitiesList(c);
  };

  useEffect(() => {
    dataFetch(true);
  }, [
    identifyAsFilter,
    skillsFilter,
    sortConfig,
    cityFilter,
    candidateStatusFilter,
    debouncedKeywordFilter,
    rowsPerPage
  ]);

  useEffect(() => {
    if (page !== 1) {
      dataFetch();
    }
  }, [page]);

  const lastCandidateElementRef = useRef();
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleEditClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("nid", id);
    navigate("/edit_candidate");
  };

  const handleIdentifyAsChange = (event) => {
    setIdentifyAsFilter(event.target.value);
    setPage(1);
    setHasMore(true);
  };

  const handleSkillsChange = (event) => {
    setSkillsFilter(event.target.value);
    setPage(1);
    setHasMore(true);
  };

  const handleCandidateStatusChange = (event) => {
    setCandidateStatusFilter(event.target.value);
    setPage(1);
    setHasMore(true);
  };  

  const handleCityChange = (event) => {
    setCityFilter(event.target.value);
    setPage(1);
    setHasMore(true);
  };

  const handleCopy = () => {
    const text = data.map((job) => Object.values(job).join(", ")).join("\n");
    navigator.clipboard.writeText(text).then(() => {
      swal("Copied!", "Table data copied to clipboard.", "success");
    });
  };

  const handleDownloadExcel = () => {
    const displayedData = data.map((Event) => ({
      "C.ID": Event.id,
      "Resume File": Event.resume_file ? Event.resume_file_url : null,
      "Name": Event.legal_name,
      "Email": Event.email,
      "Phone Number": Event.phone_number,
      "WhatsApp Number": Event.whatsapp_number,
      "Identify As": Event.identify,
      "Current Profile Role": Event.current_profile_role,
      "Key Skills": Event.key_skills,
      "Current Salary": Event.current_salary,
      "Published": Event.published == "1" ? "Active" : "InActive",
    }));
    const worksheet = XLSX.utils.json_to_sheet(displayedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Jobs");
    XLSX.writeFile(workbook, "CandidateList.xlsx");
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1); // Reset to first page on rows change
   // dataFetch(true);
  };

  
  const handleKeywordChange = (event) => {
    setKeywordFilter(event.target.value);
    setPage(0);
    //setHasMore(true);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setPage(1);
    setHasMore(true);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <span>▲</span>;
      } else if (sortConfig.direction === "desc") {
        return <span>▼</span>;
      }
    }
    return <span>↕</span>;
  };

  const handleViewClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("vid", id);
    navigate("/candidate_view");
  };

  // delete data
  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      await deleteCandidate(contactId);
      dataFetch();
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };

  const updateDownloadStatus = (id) => {
    console.log("Update download id", id);
  };

  const renderPagination = () => (
    <div className="d-md-flex d-block align-items-center justify-content-between text-center flex-wrap mt-md-0 mt-3">
      <div className="mb-md-0 mb-2">
        <h5 className="mb-0">
          Showing {Math.min((page - 1) * rowsPerPage + 1, totalEntries)} to{" "}
          {Math.min(page * rowsPerPage, totalEntries)} of {totalEntries} entries
        </h5>
      </div>
      <nav>
        <ul className="pagination pagination-circle justify-content-center">
          <li className="page-item page-indicator">
            <Link
              to="#"
              className="page-link"
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              disabled={page === 1}
            >
              <i className="fa fa-angle-double-left" />
            </Link>
          </li>
          <li className="page-item active">
            <Link to="#" className="page-link">
              {page}
            </Link>
          </li>
          <li className="page-item page-indicator">
            <Link
              to="#"
              className="page-link"
              onClick={() => setPage((prev) => (hasMore ? prev + 1 : prev))}
              disabled={!hasMore}
            >
              <i className="fa fa-angle-double-right" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );


  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Candidate List</h4>
        {paramValue==null && permission.a === true ? (
          <>
            <Link
              to={"/upload_candidate"}
              className="btn btn-primary me-3 btn-sm"
            >
              <i className="fas fa-plus me-2"></i>Upload Candidate
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="d-flex justify-content-end mb-4">
        <div className="d-flex gap-3">
          <select
            className="form-select"
            value={cityFilter}
            onChange={handleCityChange}
          >
            <option value="">Filter by City</option>
            {citiesList?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.city_name}
                </option>
              );
            })}
          </select>
          <select
            className="form-select"
            value={identifyAsFilter}
            onChange={handleIdentifyAsChange}
          >
            <option value="">Filter by Identify As</option>
            {identityList?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.identify_name}
                </option>
              );
            })}
          </select>
          <select
            className="form-select"
            value={skillsFilter}
            onChange={handleSkillsChange}
          >
            <option value="">Filter by Skills</option>
            {skillList?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.skill_title}
                </option>
              );
            })}
          </select>

          <select
            className="form-select"
            value={candidateStatusFilter}
            onChange={handleCandidateStatusChange}
          >
            <option value="">Filter by Candidate Status</option>
                 <option value="1">CV Shortlisted</option>
                  <option value="2">Reject</option>
                  <option value="3">On hold</option>
                  <option value="4">Offered</option>
                  <option value="5">DND</option>
                  <option value="6">Others</option>
                  <option value="7">In process</option>
                  <option value="8">Interview Scheduled</option>
                  <option value="9">Viewed(Action Pending)</option>
          </select>
          <input
            type="text"
            className="form-control"
            value={keywordFilter}
            onChange={handleKeywordChange}
            placeholder="Keyword"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
        <div className="row">
            <div className="col-xl-3">
                <button className="btn btn-secondary me-3" onClick={handleCopy}>
                  COPY
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleDownloadExcel}
                >
                 Download Excel
                </button>
                </div>
                <div className="col-xl-2">
                <select
                  className="form-select w-100 me-3"
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                >
                  {[10, 25, 50, 100, 1000, 2000].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th onClick={() => handleSort("id")}>C.No{renderSortIcon("id")}</th>
                  <th onClick={() => handleSort("resume_file")}>
                    Download {renderSortIcon("resume_file")}
                  </th>
                  <th onClick={() => handleSort("legal_name")}>
                    Name {renderSortIcon("legal_name")}
                  </th>
                  <th onClick={() => handleSort("email")}>
                    Email {renderSortIcon("email")}
                  </th>
                  <th onClick={() => handleSort("phone_number")}>
                    Phone Number {renderSortIcon("phone_number")}
                  </th>
                  <th onClick={() => handleSort("whatsapp_number")}>
                    WhatsApp Number {renderSortIcon("whatsapp_number")}
                  </th>
                  <th onClick={() => handleSort("identify_as")}>
                    Identify As {renderSortIcon("identify_as")}
                  </th>
                  <th onClick={() => handleSort("current_profile_role")}>
                    Current Profile Role{" "}
                    {renderSortIcon("current_profile_role")}
                  </th>
                  <th onClick={() => handleSort("key_skills")}>
                    Key Skills {renderSortIcon("key_skills")}
                  </th>
                  <th onClick={() => handleSort("current_salary")}>
                    Current Salary {renderSortIcon("current_salary")}
                  </th>
                  <th onClick={() => handleSort("published")}>
                    Published {renderSortIcon("published")}
                  </th>
                  {permission.v ? (
                    <>
                      <th>Actions</th>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((cms, index) => (
                    <tr key={index}>
                      <td>{cms.id}</td>
<td>
                        {cms.resume_file ? (
                          <a
                            onClick={() => updateDownloadStatus(cms.id)}
                            href={cms.resume_file_url}
                            download
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0a.5.5 0 0 1 .5.5V8h2.5a.5.5 0 0 1 .354.854l-3 3a.5.5 0 0 1-.708 0l-3-3A.5.5 0 0 1 5 8H7.5V.5A.5.5 0 0 1 8 0zM1.5 11a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-12a.5.5 0 0 1-.5-.5v-4z" />
                            </svg>
                          </a>
                        ) : cms.resume_url ? (
                          <a
                            onClick={() => updateDownloadStatus(cms.id)}
                            href={cms.resume_url}
                            download
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0a.5.5 0 0 1 .5.5V8h2.5a.5.5 0 0 1 .354.854l-3 3a.5.5 0 0 1-.708 0l-3-3A.5.5 0 0 1 5 8H7.5V.5A.5.5 0 0 1 8 0zM1.5 11a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-12a.5.5 0 0 1-.5-.5v-4z" />
                            </svg>
                          </a>
                        ): (
                          <></>
                        )}
                      </td>                     
<td>{cms.legal_name}</td>
                      <td>{cms.email}</td>
                      <td>{cms.phone_number}</td>
                      <td>{cms.whatsapp_number}</td>
                      <td>{cms.identify}</td>
                      <td>{cms.current_profile_role}</td>
                      <td>{cms.key_skills}</td>
                      <td>{cms.current_salary.toLocaleString()}</td>
                      <td>
                        <span
                          className={`badge badge-lg light badge-${
                            cms.published == "1" ? "success" : "danger"
                          }`}
                        >
                          {cms.published == "1" ? "Active" : "InActive"}
                        </span>
                      </td>
                      {permission.v === true ? (
                        <>
                          <td>
                            <div className="action-buttons d-flex justify-content-end">
                              {permission.v === true ? (
                                <>
                                  <Link
                                    to="#"
                                    onClick={(e) => handleViewClick(e, cms.id)}
                                    className="btn btn-success light mr-2"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="svg-main-icon"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 32 32"
                                      x="0px"
                                      y="0px"
                                    >
                                      <g data-name="Layer 21">
                                        <path
                                          d="M29,14.47A15,15,0,0,0,3,14.47a3.07,3.07,0,0,0,0,3.06,15,15,0,0,0,26,0A3.07,3.07,0,0,0,29,14.47ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        ></path>
                                        <circle
                                          cx="16"
                                          cy="16"
                                          r="3"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        ></circle>
                                      </g>
                                    </svg>
                                  </Link>
                                </>
                              ) : (
                                <></>
                              )}
                              {permission.e === true ? (
                                <>
                                  <Link
                                    to="#"
                                    onClick={(event) =>
                                      handleEditClick(event, cms.id)
                                    }
                                    className="btn btn-secondary light mr-2"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                      className="svg-main-icon"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <path
                                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                        ></path>
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          x="5"
                                          y="20"
                                          width="15"
                                          height="2"
                                          rx="1"
                                        ></rect>
                                      </g>
                                    </svg>
                                  </Link>
                                </>
                              ) : (
                                <></>
                              )}

                              {permission.d === true ? (
                                <>
                                  <Link
                                    to={"#"}
                                    className="btn btn-danger light"
                                    onClick={() => handleDeleteClick(cms.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                      className="svg-main-icon"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        ></path>
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        ></path>
                                      </g>
                                    </svg>
                                  </Link>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      {" "}
                      No Record Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div ref={lastCandidateElementRef} className="text-center" />
        </div>
      </div>
      {renderPagination()}
    </>
  );
};

export default CandidateLists;
