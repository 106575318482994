import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import { getJobDetailsAPI } from "../../api/job";
import {job_types} from "../../common/job_types";
import Parser from 'html-react-parser';

const JobView = () => {
    const jobId = localStorage.getItem('vid');
    const [job, setJob] = useState({
        job_title: "",
        min_experience: "",
        max_experience: "",
        locations: "",
        skills: [],
        job_position: "",
        job_ctc: "",
        job_details: "",
        job_sector: "",
        job_types: "",
        published: "0",
        job_publish_schedule: new Date(),
        application_deadline: new Date(),
        add_to_hot_jobs: "0",
        show_popup_how_did_you_know: false,
        profile_image: ""
    });

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const jobDetails = await getJobDetailsAPI(jobId);
                setJob(jobDetails);
            } catch (error) {
                swal("Error", "Failed to fetch job details", "error");
            }
        };

        if (jobId) {
            fetchJobDetails();
        }
    }, [jobId]);

    return (
        <>
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <h3 className="mb-0 me-auto">Job View</h3>
                <div>
                    <Link to="/job_list" className="btn btn-primary me-3 btn-sm">
                        <i className="fas fa-plus me-2"></i>Back to Listing
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card">
                        <div className="card-header border-0 pb-0">
                            <h4 className="fs-20 mb-0">Overview</h4>
                        </div>
                        <div className="card-body pt-4">
                            <div className="row">
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Job Title:</h5>
                                    <span>{job.job_title}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Experience:</h5>
                                    <span>{job.min_experience} - {job.max_experience} yrs</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Location:</h5>
                                    <span>{job.locations_name}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Skills:</h5>
                                    <span>{job.skills_name}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Vacancy:</h5>
                                    <span>{job.job_position}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">CTC:</h5>
                                    <span>{job.job_ctc}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Job Type:</h5>
                                    <span>{job.job_types?.split(",").map((id)=>job_types[id-1]?.title).join(", ")}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Job Sector:</h5>
                                    <span>{job.job_sector==1?"Tech":"Non-Tech"}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Posted Date:</h5>
                                    <span>{new Date(job.job_publish_schedule).toLocaleDateString()}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Application Deadline:</h5>
                                    <span>{new Date(job.application_deadline).toLocaleDateString()}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Published:</h5>
                                    <span>{job.published === "0" ? "No" : "Yes"}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Add to Hot Jobs:</h5>
                                    <span>{job.add_to_hot_jobs === "0" ? "No" : "Yes"}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Show Popup "How Did You Know":</h5>
                                    <span>{job.show_popup_how_did_you_know ? "Yes" : "No"}</span>
                                </div>
                                <div className="col-md-6 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Profile Image:</h5>
                                    <span>
                                        {job.profile_image ? (
                                            <img src={job.profile_image} alt="Profile" style={{ maxWidth: '100px' }} />
                                        ) : 'No image'}
                                    </span>
                                </div>
                                <div className="col-md-12 mb-3 d-flex">
                                    <h5 className="mb-1 fs-14 custom-label">Job Details:</h5>
                                    <span>{Parser(job.job_details)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer border-0 pt-0">
                           
                        </div>
                    </div>
                </div>	
            </div>
        </>
    );
};

export default JobView;