import React, { useEffect, useState } from 'react';
import { allRead, getAllNotification } from '../../api/notification';
import { NotificationItemComponent } from '../../layouts/nav/Header';

const NotificationsLists = () => {
  const [notificationsData, setNotificationsData] = useState([]);
	useEffect(() => {
		dataFetch();
	}, []);
	const dataFetch = async () => {
		let params = {
			user_type: '0',
			user_id: '1',
		};
		let data = await getAllNotification(params);
		setNotificationsData(data);
		if((data?.notifications || [] ).length > 0 ) {
			await allRead({
				user_type: "0",
				user_id: "1",
			  });
		}
	};


	return (
		<div className='card'>
			<div className='card-header  border-0 pb-0'>
				<h4 className='card-title'>Notifications</h4>
			</div>
			<div className='card-body'>
				<div
					id='DZ_W_Todo1'
					className='widget-media dlab-scroll ps ps--active-y'>
					<ul className='timeline'>
						{(notificationsData?.notifications || []).length > 0 ? (
							notificationsData?.notifications.map((noti, i) => (
								<NotificationItemComponent item={noti} key={i} />
							))
						) : (
							<li>No notification found</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};
export default NotificationsLists;
