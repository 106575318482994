import React, { useReducer, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from './Logout';
import profile from "../../../images/profile/pic1.jpg";
import { sidebarAPI } from "../../api/menu";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  let d = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const [menuList, setMenuList] = useState([]);
  const [profileDetail, setProfileDetail] = useState({});

  const location = useLocation();

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    let pdata = JSON.parse(localStorage.getItem('userDetails'));
    setProfileDetail(pdata);
    fetchMenuItems(pdata.roleid);
  }, []);

  const fetchMenuItems = async (roleid) => {
    try {
      let mdata = await sidebarAPI(roleid);
      if (mdata == false) {
        setMenuList([]);
      } else {
        setMenuList(mdata);
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = status => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  const handleSubmenuActive = status => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  const getPathName = () => {
    let path = location.pathname;
    path = path.split("/");
    return path[path.length - 1];
  };

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <Dropdown as="div" className=" header-profile2 dropdown">
          <Dropdown.Toggle
            as="div"
            variant=""
            className=" i-false c-pointer"
            role="button"
            data-toggle="dropdown"
          >
            <div className="header-info2 d-flex align-items-center">
              <img src={profile} width={20} alt="" />
              <div className="d-flex align-items-center sidebar-info">
                <div>
                  <span className="font-w400 d-block">{profileDetail.displayName}</span>
                  <small className="text-end font-w400">{profileDetail.roleName}</small>
                </div>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu align="end" className=" dropdown-menu dropdown-menu-end">              
            <Link to="/setting">
                <i className=""></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nav-text"> &nbsp; Setting</span>
            </Link>
            <LogoutPage />
          </Dropdown.Menu>
        </Dropdown>
        <ul className="metismenu" id="menu">
          {menuList?.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>{data.title}</li>
              );
            } else {
              return (
                <li className={` ${state.active === data.title || getPathName() === data.to ? 'mm-active' : ''}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link to={"#"}
                        className="has-arrow"
                        onClick={() => { handleMenuActive(data.title) }}
                      >
                        <i className={data.iconClass}></i>
                        <span className="nav-text">{data.title}</span>
                        <span className="badge badge-xs style-1 badge-danger">{data.update}</span>
                      </Link>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                          {data.content.map((submenu, subIndex) => (
                            <li key={subIndex}
                              className={`${state.activeSubmenu === submenu.title || getPathName() === submenu.to ? "mm-active" : ""}`}
                            >
                              <Link to={submenu.to} className={submenu.hasMenu ? 'has-arrow' : ''}
                                onClick={() => { handleSubmenuActive(submenu.title) }}
                              >
                                {submenu.title}
                              </Link>
                              <Collapse in={state.activeSubmenu === submenu.title ? true : false}>
                                <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                  {submenu.content && submenu.content.map((subsubmenu, subsubIndex) => (
                                    <li key={subsubIndex}>
                                      <Link className={`${getPathName() === subsubmenu.to ? "mm-active" : ""}`} to={subsubmenu.to}>{subsubmenu.title}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </Collapse>
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link to={data.to}>
                      <i className={data.iconStyle}></i>
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
        <hr/>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;