import React, { useEffect, useState, useRef } from 'react';
import { getAllActivities } from "../../api/activities";
import debounce from 'lodash.debounce';

const ActivityList = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState({ search: '', sortField: '', sortOrder: '' });
    const observer = useRef();
    const lastJobElementRef = useRef();

    const dataFetch = async (reset = false) => {
        let params = {
            page,
            limit:10,
            keyword: filters.search,
            sort_by: filters.sortField,
            sort_direction: filters.sortOrder,
        };
        let response = await getAllActivities(params);
        if (response.length < 10) setHasMore(false);
        setData(prevData => reset ? response : [...prevData, ...response]);
    };

    useEffect(() => {
        dataFetch(true);
    }, [filters]);

    useEffect(() => {
        if (page !== 1) {
            dataFetch();
        }
    }, [page]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                loadMore();
            }
        }, options);

        if (lastJobElementRef.current) {
            observer.current.observe(lastJobElementRef.current);
        }

        return () => {
            if (observer.current && lastJobElementRef.current) {
                observer.current.unobserve(lastJobElementRef.current);
            }
        };
    }, [hasMore]);

    const handleFilterChange = debounce((event) => {
        const { value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            search: value,
        }));
        setPage(1);
        setHasMore(true);
    }, 300);

    const handleSort = (field) => {
        let sortOrder = 'asc';
        if (filters.sortField === field && filters.sortOrder === 'asc') {
            sortOrder = 'desc';
        } else if (filters.sortField === field && filters.sortOrder === 'desc') {
            sortOrder = 'asc';
        }

        setFilters(prevFilters => ({
            ...prevFilters,
            sortField: sortOrder ? field : '',
            sortOrder: sortOrder,
        }));
        setPage(1);
        setHasMore(true);
    };

    const getSortIcon = (field) => {
        if (filters.sortField === field) {
            if (filters.sortOrder === 'asc') return <span>▲</span>;;
            if (filters.sortOrder === 'desc') return <span>▼</span>;
        }
        return <span>↕</span>;
    };

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return new Date(dateString).toLocaleString('en-US', options);
    };

    return (
        <>
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <h4 className="fs-20 font-w600 me-auto">Activities List</h4>
            </div>
            <div className="row mb-4">
        <div className="col-md-6 ms-auto">
          <input
            type="text"
            className="form-control"
            placeholder="Filter by activities"
            onChange={handleFilterChange}
          />
        </div>
      </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="table-responsive">
                        <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th onClick={() => handleSort('page_url')}>
                                        Page URL {getSortIcon('page_url')}
                                    </th>
                                    <th onClick={() => handleSort('activity_by')}>
                                        Created by {getSortIcon('activity_by')}
                                    </th>
                                    <th onClick={() => handleSort('role_by')}>
                                        Role {getSortIcon('role_by')}
                                    </th>
                                    <th onClick={() => handleSort('activity')}>
                                        Activity {getSortIcon('activity')}
                                    </th>
                                    <th onClick={() => handleSort('created_at')}>
                                        Created Date {getSortIcon('created_at')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((activity, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{activity.page_url}</td>
                                        <td>{activity.activity_by}</td>
                                        <td>{activity.role_by}</td>
                                        <td>{activity.activity}</td>
                                        <td>{formatDate(activity.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div ref={lastJobElementRef} className="text-center">
                        {hasMore && <span>Loading more...</span>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActivityList;
