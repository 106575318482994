import React, { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { 
  reportingSkills, 
  reportingNonTechSkills, 
  reportingLocations, 
  reportingExperiences, 
  reportingIdentify 
} from "../../api/skillm";

const Reporting = () => {
  const [talentData, setTalentData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [itSkillsData, setItSkillsData] = useState([]);
  const [nonItSkillsData, setNonItSkillsData] = useState([]);
  const reportRef = useRef();

  useEffect(() => {
    setAllData();
  }, []);

  const setAllData = async () => {
    let skill = await reportingSkills();
    setItSkillsData(await filterData(skill));
    let identify = await reportingIdentify();
    setTalentData(await filterData(identify));
    let expr = await reportingExperiences();
    setExperienceData(await filterData(expr));
    let city = await reportingLocations();
    setCityData(await filterData(city));
    let notech = await reportingNonTechSkills();
    setNonItSkillsData(await filterData(notech));  
  };

  const filterData = async (skill) => {
    return skill.map((item) => {
      let [label, percentage] = Object.entries(item)[0];
      percentage = parseInt(percentage);
      return { label, percentage: isNaN(percentage) ? 0 : percentage };
    });
  };

  const renderProgressBars = (data, colorClass) =>
    data.map((item, index) => (
      <div key={index} className="row mb-2">
        <div className="col-4">
          <p className="mb-0">{item.label}</p>
        </div>
        <div className="col-8">
          <div className="progress_new">
            <div
              className={`progress-bar ${colorClass}`}
              role="progressbar"
              aria-valuenow={item.percentage}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${item.percentage}%` }}
            >
              {item.percentage}%
            </div>
          </div>
        </div>
      </div>
    ));

    const downloadAsImage = async () => {
      // Ensure the background is set to white
      reportRef.current.style.backgroundColor = 'white';
  
      const canvas = await html2canvas(reportRef.current, {
          backgroundColor: 'white',  // Ensures transparent areas are white
      });
  
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'report.png';
      link.click();
  
      // Restore the original background
      reportRef.current.style.backgroundColor = '';
  };
  
  const downloadAsPDF = async () => {
      // Ensure the background is set to white
      reportRef.current.style.backgroundColor = 'white';
  
      const canvas = await html2canvas(reportRef.current, {
          backgroundColor: 'white',  // Ensures transparent areas are white
      });
  
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('portrait', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('report.pdf');
  
      // Restore the original background
      reportRef.current.style.backgroundColor = '';
  };
  
  

  return (
    <div>
      <div className="mt-3 d-flex justify-content-end mb-4 flex-wrap">
        <button className="btn btn-primary me-2" onClick={downloadAsImage}>Download as Image</button>
        <button className="btn btn-primary" onClick={downloadAsPDF}>Download as PDF</button>
      </div>
      <div className="card" ref={reportRef}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <h4>TALENT</h4>
              <br />
              {renderProgressBars(talentData, "bg-danger")}
            </div>
            <div className="col-md-4">
              <h4>CITY</h4>
              <br />
              {renderProgressBars(cityData, "bg-warning")}
            </div>
            <div className="col-md-4">
              <h4>WORK EXPERIENCE</h4>
              <br />
              {renderProgressBars(experienceData, "bg-success")}
            </div>
          </div>
        
          <div className="row">
            <div className="col-md-4">
              <h4>IT SKILLS</h4>
              <br />
              {renderProgressBars(itSkillsData, "bg-info")}
            </div>
            <div className="col-md-4">
              <h4>NON-IT SKILLS</h4>
              <br />
              {renderProgressBars(nonItSkillsData, "progress-bar-striped bg-secondary")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reporting;