import React, { useState } from 'react';
import swal from "sweetalert";
import { Link, useNavigate } from 'react-router-dom';
import { addNewCandidate } from "../../api/candidate";
import { ThreeDots } from 'react-loader-spinner'; // Importing a loading spinner library

const initialState = {
    excelfile: "",
    resumes: []
};

const AddCandidate = () => {
    const [data, setData] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [responses, setResponses] = useState(null);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const files = e.target.files;
        const name = e.target.name;
        if (name === "excelfile") {
            if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                swal("Invalid file type", "Please upload an .xlsx file", "error");
                return;
            }
            setData(prevState => ({
                ...prevState,
                excelfile: files[0]
            }));
        } else if (name === "resumes") {
            let valid = true;
            for (let i = 0; i < files.length; i++) {
                if (files[i].type !== 'application/pdf' || files[i].size > 1024 * 1024) {
                    valid = false;
                    break;
                }
            }
            if (!valid) {
                swal("Invalid file type or size", "Please upload PDFs not exceeding 1 MB each", "error");
                return;
            }
            setData(prevState => ({
                ...prevState,
                resumes: files
            }));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        for (const key in data) {
            if (key === "resumes") {
                for (let i = 0; i < data[key].length; i++) {
                    formData.append('resumes', data[key][i]);
                }
            } else {
                formData.append(key, data[key]);
            }
        }

        try {
            let res = await addNewCandidate(formData);
            if (res?.data) {
                setResponses({
                    account_created: res.data.account_created || [],
                    already_exists: res.data.already_exists || [],
                    invalid_email: res.data.invalid_email || [],
                    response_error: res.data.response_error || []
                });
            }
        } catch (error) {
            swal('Error', 'There was an error creating the Candidate', "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <h4 className="fs-20 font-w600 me-auto">Upload Candidates</h4>
                <Link to="/c_lists" className="btn btn-primary me-3 btn-sm">
                        <i className="fas fa-plus me-2"></i>Back to Listing
                </Link>
            </div>

            {!responses && (
            <><div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attachment</h4>
                        </div>
                        <div className="card-body">
                            <p className="text-muted">Please upload an .xlsx file for Excel and PDF files for resumes. Each PDF should not exceed 1 MB.</p>
                            <div className="mb-3">
                                <label className="form-label">Excel File</label>
                                <input type="file" name="excelfile" className="form-control" onChange={handleFileChange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Resumes</label>
                                <input type="file" name="resumes" className="form-control" multiple onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center">
                <button type="submit" className="btn btn-primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? (
                        <>
                            <ThreeDots
                                visible={true}
                                height="80"
                                width="80"
                                color="#4fa94d"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /> Uploading...
                        </>
                    ) : (
                        "Upload Candidates"
                    )}
                </button>
            </div>
</> 
)}
            {responses && (
                <div className="row mt-3">
                    <div> <Link to="/c_lists" className="btn btn-primary me-3 btn-sm">Back to List</Link> </div>
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">
                                    Result Response - 
                                    Account Created: {responses.account_created.length}, 
                                    Already Exists: {responses.already_exists.length}, 
                                    Invalid Email: {responses.invalid_email.length}, 
                                    Response Error : {responses?.response_error?.length}
                                </h4>
                            </div>
                            <div className="card-body">
                                <p><strong>Account Created:</strong> {responses.account_created.join(', ')}</p>
                                <p><strong>Already Exists:</strong> {responses.already_exists.join(', ')}</p>
                                <p><strong>Invalid Email:</strong> {responses.invalid_email.join(', ')}</p>
                                <p><strong>Response Error:</strong> {responses?.response_error?.join(', ')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddCandidate;