import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import swal from "sweetalert";
import {addNewSkill} from "../../api/skill";


const initialState = {
	title:'',
	detail:'1',
	published:'1'
};

const NewSkill = (props) => {
	const { addCard, setAddCard, dataFetch } = props;
	const [addFormData, setAddFormData ] = useState(initialState); 

	// Add contact function
    const handleAddFormChange = (event) => {
        event.preventDefault();    
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...addFormData};
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };
    
     //Add Submit data
    const handleAddFormSubmit = async(event)=> {
        event.preventDefault();
        var error = false;
		var errorMsg = '';
        if(addFormData.title === ""){
            error = true;
			errorMsg = 'Please fill Skill Title';
        }
        if(!error){
            const newContact = {
				skill_title:addFormData.title,
				skill_type:addFormData.detail,
				status:addFormData.published,
				"created_by":1
			};
			await addNewSkill(newContact);
			dataFetch();
            setAddCard(false);
			setAddFormData(initialState)
            swal('Successfully Added Skill!', 'Successfully Added', "success");            
        }else{
			swal('Oops', errorMsg, "error");
		}
    };

	
	return (
		<>
			<Modal className="modal fade bd-example-modal-lg" show={addCard} onHide={() => setAddCard(false)} centered size="lg">
				<div role="document">
					<div className="">
						<form>
							<div className="modal-header">
								<h4 className="modal-title fs-20">Add New Skill</h4>
								<button type="button" className="btn-close" onClick={() => setAddCard(false)} data-dismiss="modal">
									<span></span>
								</button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="form-group mb-6">
											<div className="row">
												<div className="form-group mb-6 col-md-12 mb-3">
													<label className="text-black font-w500">Skill Name <span className="text-danger">*</span> </label>
													<div className="contact-name">
														<input
															type="text"
															className="form-control"
															autoComplete="off"
															name="title"
															required="required"
															onChange={handleAddFormChange}
															placeholder="Enter Skill Name"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>
										
										<div className="form-group mb-3">
											<label className="text-black font-w500">Skill Type</label>
											<div className="contact-name">
												<input
													className="form-check-input"
													type="radio"
													name="detail"
													value="1"
													id="detailTech"
													checked={ addFormData.detail==1 }
													onChange={handleAddFormChange}
												/>
												<label className="form-check-label" htmlFor="statusActive">
													Tech &nbsp;&nbsp;&nbsp;
												</label>
												<input
													className="form-check-input"
													type="radio"
													name="detail"
													value="0"
													id="detailNonTech"
													checked={ addFormData.detail!=1 }
													onChange={handleAddFormChange}
												/>
												<label className="form-check-label" htmlFor="statusInactive">
													Non-Tech
												</label>
												<span className="validation-text"></span>
											</div>
										</div>

										<div className="form-group mb-3">
											<label className="text-black font-w500">Status</label>
											<div className="contact-name">
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="1"
													id="statusActive"
													checked={true}
													onChange={handleAddFormChange}
												/>
												<label className="form-check-label" htmlFor="statusActive">
													Active &nbsp;&nbsp;&nbsp;
												</label>
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="0"
													id="statusInactive"
													onChange={handleAddFormChange}
												/>
												<label className="form-check-label" htmlFor="statusInactive">
													InActive
												</label>
												<span className="validation-text"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-success" onClick={handleAddFormSubmit}>
									Add
								</button>
								<button type="button" onClick={() => setAddCard(false)} className="btn btn-danger">
									<i className="flaticon-delete-1"></i> Discard
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default NewSkill;