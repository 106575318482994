import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { useNavigate, Link } from 'react-router-dom';
import { getSetting, updateSetting } from '../../api/common';

const initialState = {
  website_name: '',
  from_email: '',
  to_email: '',
  meta_title: '',
  meta_keywords: '',
  meta_description: '',
  analytics_code: '',
  address: '',
  phone_no: '',
  website_url: '',
  footer_content: '',
  instragram_feed: '',
  instragram_link: '',
  facebook_link: '',
  twitter_link: '',
  youtube_link: '',
  linkedin_link: '',
};

const EditSetting = () => {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const settingsData = await getSetting();
        console.log("settingsData", settingsData)
        setData(settingsData);
      } catch (error) {
        swal('Error', 'Failed to fetch data', 'error');
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await updateSetting(1, data);
      if (res !== false) {
        if (res?.error) {
          swal('Error', res.error, 'error');
        } else {
          swal('Success', 'Settings updated successfully', 'success');
          navigate('/');
        }
      } else {
        swal('Error', 'There was an error updating the settings. Please contact the administrator!', 'error');
      }
    } catch (error) {
      swal('Error', 'There was an error updating the settings', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Edit Settings</h4>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Settings Details</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Generate fields dynamically based on provided list */}
                  {Object.keys(initialState).map((key) => (
                    <div className="col-md-6 mb-3" key={key}>
                      <label className="form-label">{key.replace(/_/g, ' ')}</label>
                      <input
                        type="text"
                        name={key}
                        className="form-control"
                        onChange={handleChange}
                        value={data[key]}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Loading...' : 'Update Settings'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSetting;
