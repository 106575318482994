import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const candidateActivities = async (data) => {
    try {
        const postData = {
            loginid: data.userid,
            page_url: data.pageUrl,
            activity: JSON.stringify(data.data),
           // browser_info: JSON.stringify(browserInfo), // Add browser information
            created_at: new Date().toISOString(),
        };

        // Capture request and response information
        const response = await axios.post(
            `${process.env.REACT_APP_API_HOST}admin/logs/activities`,
            postData
        );
        return response;
    } catch (error) {
        console.error("Error on inserting logs", error);
        return false;
    }
}


export const getAllActivities=async(params)=>{
    try {
        let config =  await fetchUserData();
        const response = await axios.post(`${process.env.REACT_APP_API_HOST}admin/logs/listing`,
            params,
            config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching Activities items:", error);
      }
  }