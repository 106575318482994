import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { setSchedulerData } from "../../api/notification";

const initialState = {
  notification_for: "",
  notification_type: "",
  notification_subject: "",
  time: "0",
  notification_actions: "",
};

const NotificationSchedule = (props) => {
  const { isOpen, setIsOpen, data } = props;
  const [addFormData, setAddFormData] = useState(initialState);

  const [notificationTypes] = useState([
    { ntype: 1, title: "WhatsApp" },
    { ntype: 2, title: "Email" },
  ]);

  const [notificationFor] = useState([
    { ntype: 1, title: "Recruiter" },
    { ntype: 2, title: "Candidate" },
  ]);

  const notificationAction = {
    1: {
      3: "Account Expire Notification",
      4: "General Quota Expire",
      5: "Applicant Quota Expire",
      6: "Job Quota Expire",
      7: "Sub Recruiter Quota Expire",
      8: "Login Alert",
      
    },
    2: {
      8: "Login Alert",
	  9: "Job Alert",
      10: "Event Alert",
    },
  };

  const handleAddFormChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setAddFormData({ ...addFormData, [fieldName]: fieldValue });
  };

  const handleNotificationSchedule = async (event) => {
    event.preventDefault();
    let error = false;
    let errorMsg = "";

    if (
      addFormData.notification_type === "" ||
      addFormData.notification_subject === ""
    ) {
      error = true;
      errorMsg = "Please fill Required Data";
    }

    if (!error) {
      await setSchedulerData(addFormData);
      setIsOpen(false);
      swal("Successfully Added Scheduler!", "Successfully Added", "success");
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const filteredNotificationActions = useMemo(() => {
    const notificationForType = addFormData.notification_for;
    return notificationForType ? notificationAction[notificationForType] : {};
  }, [addFormData.notification_for]);

  const filterNotificationSubject = useMemo(() => {
    const newData = (data || []).filter(
      (val) => val.ntype === addFormData.notification_type
    );
    return newData;
  }, [addFormData.notification_type, data]);

  return (
    <Modal
      className="modal fade bd-example-modal-lg"
      show={isOpen}
      onHide={() => setIsOpen(false)}
      centered
      size="lg"
    >
      <div role="document">
        <div className="">
          <form>
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title fs-20">Notification Schedule</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsOpen(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>

            {/* body */}
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">

 {/* notification for */}
 <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-black font-w500 mt-2">
                          Notification For{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <select
                            className="form-select"
                            id="notification_for"
                            name="notification_for"
                            onChange={handleAddFormChange}
                          >
                            <option value="">Please select</option>
                            {(notificationFor || [])?.map((item) => (
                              <option key={item.ntype} value={item.ntype}>
                                {item.title}
                              </option>
                            ))}
                          </select>

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* notification action */}
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-black font-w500 mt-2">
                          Notification Action{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <select
                            className="form-select"
                            id="notification_actions"
                            name="notification_actions"
                            onChange={handleAddFormChange}
                          >
                            <option value="">Please select</option>
                            {Object.entries(filteredNotificationActions)?.map(
                              ([key, value]) => (
                                <option key={key} value={key}>
                                  {value}
                                </option>
                              )
                            )}
                          </select>

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                  </div>

				  

                  {/* notification Subject */}
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-black font-w500 mt-2">
                          Notification Type{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <select
                            className="form-select"
                            id="notification_type"
                            name="notification_type"
                            onChange={handleAddFormChange}
                          >
                            <option value="">Please select</option>
                            {(notificationTypes || [])?.map((item) => {
                              return (
                                <>
                                  <option value={item.ntype}>
                                    {item.title}
                                  </option>
                                </>
                              );
                            })}
                          </select>

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* notification Subject */}
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-black font-w500 mt-2">
                          Notification Subject{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <select
                            className="form-select"
                            id="notification_subject"
                            name="notification_subject"
                            onChange={handleAddFormChange}
                          >
                            <option value="">Please select</option>
                            {(filterNotificationSubject || [])?.map((item) => {
                              return (
                                <>
                                  <option value={item.id}>
                                    {item.subject}
                                  </option>
                                </>
                              );
                            })}
                          </select>

                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* notification time */}
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Notification Time
                        </label>
                        <div>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="time"
                            value="17"
                            checked={addFormData.time == "17"}
                            onChange={handleAddFormChange}
                          />
                          <label
                            className="form-check-label mb"
                            htmlFor="publishActive"
                          >
                            5pm &nbsp;&nbsp;&nbsp;
                          </label>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="time"
                            value="22"
                            checked={addFormData.time == "22"}
                            onChange={handleAddFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishInactive"
                          >
                            10pm &nbsp;&nbsp;&nbsp;
                          </label>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="time"
                            value="0"
                            checked={addFormData.time == "0"}
                            onChange={handleAddFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishInactive"
                          >
                            Instant
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleNotificationSchedule}
              >
                Add
              </button>
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationSchedule;
