import React,{useState, useEffect} from 'react';
import {dashboardJobsAPI} from "../../api/dashboard";

const DashboardJobs = ()=>{
    const [appl, setApp]= useState({});

    useEffect(() => {
		fetchApplicationData();
	}, []);
	
	const fetchApplicationData = async()=>{
		let d = await dashboardJobsAPI();
        setApp(d);
	}

    return <>
    <div className="col-xl-12">
							<div className="card">
								<div className="card-body">
								<h5 class="card-title">Jobs</h5>
								<hr/>
									<div className="row separate-row">
										<div className="col-sm-6">
											<div className="job-icon pb-4 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.total_jobs}</h2>
														<i className={`fa-solid ms-3 ${appl?.total_jobs_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Total Jobs</span>
												</div>	
												
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.live_on_site}</h2>
														<i className={`fa-solid ms-3 ${appl?.live_on_site_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Live on Site</span>
												</div>	
												
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.scheduled_jobs}</h2>
														<i className={`fa-solid ms-3 ${appl?.scheduled_jobs_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">scheduled jobs</span>
												</div>	
												
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.unverified_jobs}</h2>
														<i className={`fa-solid ms-3 ${appl?.unverified_jobs_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Unverified Jobs</span>
												</div>	
												
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
         </>
}

export default DashboardJobs;