import React from 'react';
import { Modal } from 'react-bootstrap';

const EditPlan = (props) => {
	const { editModal, setEditModal, handleEditFormChange, handleEditFormSubmit, editFormData } = props;
	return (
		<>
			<Modal className="modal fade bd-example-modal-lg" show={editModal} onHide={setEditModal} centered size="lg">
				<div role="document">
					<div>
						<form >
							<div className="modal-header">
								<h4 className="modal-title fs-20">Edit Plan</h4>
								<button type="button" className="btn-close" onClick={() => setEditModal(false)} data-dismiss="modal"><span></span></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Plan Name</label>
													<div className="plan_name">
														<input type="text" className="form-control" autocomplete="off"
															name="plan_name" required="required"
															value={editFormData.plan_name}
															onChange={handleEditFormChange}
															placeholder="Enter Plan Name"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
												<div class="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Validity</label>
													<div className="contact-name">
														<input type="number" className="form-control" autocomplete="off"
															name="plan_days" required="required"
															value={editFormData.plan_days}
															onChange={handleEditFormChange}
															placeholder="plan days"
														/>
														<span className="validation-text"></span>
													</div>
												</div>

											</div>
										</div>
										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Currency Code</label>
													<select className='form-control' name="currency_code">
														<option value="INR">INR</option>
														<option value="GBP">GBP</option>
														<option value="USD">USD</option>
													</select>
												</div>
												<div class="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Plan Price</label>
													<input
														type="number"
														className="form-control"
														autoComplete="off"
														name="plan_price"
														required="required"
														value={editFormData.plan_price}
														onChange={handleEditFormChange}
														placeholder="plan Price"
													/>
												</div>
											</div>
										</div>


										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Jobs limit</label>
													<div className="contact-name">
														<input type="number" className="form-control" autocomplete="off"
															name="jobs_limit" required="required"
															value={editFormData.jobs_limit}
															onChange={handleEditFormChange}
															placeholder="jobs limit"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
												<div class="form-group mb-3 col-md-6">
													<label className="text-black font-w500">Sub Recruiter Limit</label>
													<div className="contact-name">
														<input type="number" className="form-control" autocomplete="off"
															name="sub_recruiter_limit" required="required"
															value={editFormData.sub_recruiter_limit}
															onChange={handleEditFormChange}
															placeholder="sub recruiter limit"
														/>
														<span className="validation-text"></span>
													</div>
												</div>

											</div>
										</div>
										<div className="form-group mb-3">
											<div className="row">
												<div className="form-group mb-3 col-md-4">
													<label className="text-black font-w500">Total Unlock Quota</label>
													<div className="contact-name">
														<input type="number" className="form-control" autocomplete="off"
															name="total_unlock_quota" required="required"
															onChange={handleEditFormChange}
															value={editFormData.total_unlock_quota}
															placeholder="Total Unlock Quota"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
												<div class="form-group mb-3 col-md-4">
													<label className="text-black font-w500">General Unlock Quota</label>
													<div className="contact-name">
														<input type="number" className="form-control" autocomplete="off"
															name="general_unlock_quota" required="required"
															onChange={handleEditFormChange}
															value={editFormData.general_unlock_quota}
															placeholder="General Unlock Quota"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
												<div class="form-group mb-3 col-md-4">
													<label className="text-black font-w500">Applicants Unlock Quota</label>
													<div className="contact-name">
														<input type="number" className="form-control" autocomplete="off"
															name="applicants_unlock_quota" required="required"
															onChange={handleEditFormChange}
															value={editFormData.applicants_unlock_quota}
															placeholder="Applicants Unlock Quota"
														/>
														<span className="validation-text"></span>
													</div>
												</div>

											</div>
										</div>

										<div className="form-group mb-3">
											<label className="text-black font-w500">Status</label>
											<div className="contact-name">
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="1"
													id="statusActive"
													checked={editFormData.published == "1"}
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusActive">
													Active &nbsp;&nbsp;&nbsp;
												</label>
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="0"
													id="statusInactive"
													checked={editFormData.published != "1"}
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusInactive">
													InActive
												</label>
												<span className="validation-text"></span>
											</div>
										</div>


									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-primary" onClick={handleEditFormSubmit}>Save</button>
								<button type="button" onClick={() => setEditModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
							</div>
						</form>

					</div>
				</div>
			</Modal>
		</>
	)
}
export default EditPlan;