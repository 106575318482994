import axios from "axios";
import { fetchUserData, fetchUserDataWithMutipart } from "./usrinfo";


export const candidateListAPI=async(params)=>{

    try{
        let config =  await fetchUserData();
        let res = await axios.post(
               `${process.env.REACT_APP_API_HOST}admin/candidate`,
               params,
               config
           );
           return res.data.data==0?[]:res.data.data
        } catch (error) {
           console.error("Error on fetching candidate logs", error);
           return [];
       }
}


export const getCandidateCities=async()=>{
    try {
        let config =  await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/candidate/cities`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
}


export const getCandidateSkills=async()=>{
    try {
        let config =  await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/candidate/skills`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
}




export const getCandidateDetailsAPI=async(id)=>{
    try {
        let config =  await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/candidate/${id}`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
}

export const addNewCandidate = async(data)=>{
    try{
     let config =  await fetchUserDataWithMutipart();
     let res = await axios.post(
            `${process.env.REACT_APP_API_HOST}admin/candidate/upload`,
            data,
            config
        );
     return res.data;
    } catch (error) {
        console.error("Error on inserting logs", error);
        return error.data.errors;
    }   
}

export const updateCandidateAPI = async (id, data) => {
    try {
        let config =  await fetchUserData();
        console.log("API data payload", data);
       let res =  await axios.put(
            `${process.env.REACT_APP_API_HOST}admin/candidate/${id}`, // Ensure your URL is correct
            data,
            config
        );
        return res.data;
    } catch (error) {
        console.error("Error on editing Candidate", error);
    }
}

export const deleteCandidate = async (id) => {
    try {
        let config =  await fetchUserData();
        await axios.delete(
            `${process.env.REACT_APP_API_HOST}admin/candidate/${id}`,
            config
        );
    } catch (error) {
        console.error("Error on deleting Candidate", error);
    }
}