import React, { useEffect, useState } from 'react';
import { roleMenuListAPI, addNewRole } from "../../api/role";
import swal from "sweetalert";


const NewRole = (props) => {
	const { setAddCard, fetchData } = props;
	const [menuData, setMenuData] = useState([]);
	const [formState, setFormState] = useState({
		role_name: "",
		menus: [],
		permissions: [],
		status: "1",
		created_by: 1
	});

	


	useEffect(() => {
		fetchMenuData();
	}, []);

	const fetchMenuData = async () => {
		let data = await roleMenuListAPI();
		setMenuData(data);
	}

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		const newValue = type === 'checkbox' ? checked : value;

		setFormState(prevState => ({
			...prevState,
			[name]: newValue
		}));
	}

	

	const handleAddFormSubmit = async (e) => {
		e.preventDefault();

		var error = false;
		var errorMsg = '';
        if(formState.role_name === ""){
            error = true;
			errorMsg = 'Please fill Role Name';
        }

		if(!error){

				const menus = [];
				const permissions = [];

				menuData.forEach((menu) => {
					const menuPermissions = [];

					if (formState[`create_${menu.id}`]) menuPermissions.push("C");
					if (formState[`view_own_${menu.id}`]) menuPermissions.push("VO");
					if (formState[`edit_own_${menu.id}`]) menuPermissions.push("EO");
					if (formState[`edit_delete_any_${menu.id}`]) menuPermissions.push("EDA");
					if (formState[`view_any_${menu.id}`]) menuPermissions.push("VA");

					if (menuPermissions.length > 0) {
						menus.push(menu.id);
						permissions.push(menuPermissions);
					}
				});

				const dataToSubmit = {
					role_name: formState.role_name,
					menus,
					permissions,
					created_by: formState.created_by,
					status: formState.status
				};

				try {
					await addNewRole(dataToSubmit);
					await fetchData();
					swal('Good job!', 'Successfully Added', "success");
					setAddCard(false);
				} catch (error) {
					console.error("Error submitting form:", error);
					swal('Oops', error, "error");
				}
		}else{
			swal('Oops', errorMsg, "error");
		}
			
	}

	return (
		<>
			<div role="document">
				<div className="">
					<form onSubmit={handleAddFormSubmit}>
						<div className="modal-header">
							<h4 className="modal-title fs-20">Add New Role</h4>
							<button type="button" className="btn-close" onClick={() => setAddCard(false)} data-dismiss="modal"><span></span></button>
						</div>
						<div className="modal-body">
							<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
							<div className="add-contact-box">
								<div className="add-contact-content">
									<div className="form-group mb-3">
										<label className="text-black font-w500">Role Name</label>
										<div className="contact-name">
											<input type="text" className="form-control" autoComplete="off"
												name="role_name" required="required"
												onChange={handleChange}
												placeholder="Please Enter Role Name"
											/>
											<span className="validation-text"></span>
										</div>
									</div>
									<div className="form-group mb-3">
										<label className="text-black font-w500">Menus</label>
									</div>

									<div className="form-group mb-3">
										<div className="table-responsive">
											<table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer" >
												<thead>
													<tr>
														<th>Menu</th>
														<th>Menu Access</th>
														<th>Create</th>
														<th>Edit</th>
														<th>Edit/Delete Any</th>
														<th>List Any</th>
													</tr>
												</thead>
												<tbody>
													{menuData.map((item, index) => (
														<tr key={index}>
															<td>{item.menu_name}</td>
															<td><input type="checkbox" value="VO" name={`view_own_${item.id}`} onChange={handleChange} checked={formState[`view_own_${item.id}`] || false} disabled={item.view_own === "1"} /> </td>
															<td><input type="checkbox" value="C" name={`create_${item.id}`} onChange={handleChange} checked={formState[`create_${item.id}`] || false} disabled={item.create === "1"} /> </td>
															<td><input type="checkbox" value="EO" name={`edit_own_${item.id}`} onChange={handleChange} checked={formState[`edit_own_${item.id}`] || false} disabled={item.edit_own === "1"} /> </td>
															<td><input type="checkbox" value="EDA" name={`edit_delete_any_${item.id}`} onChange={handleChange} checked={formState[`edit_delete_any_${item.id}`] || false} disabled={item.edit_delete_any === "1"} /></td>
															<td><input type="checkbox" value="VA" name={`view_any_${item.id}`} onChange={handleChange} checked={formState[`view_any_${item.id}`] || false} disabled={item.view_any === "1"} /> </td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>


									<div className="form-group mb-3">
                      <label className="text-black font-w500">Status</label>
                      <div className="contact-name">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          value="1"
                          id="statusActive"
                          checked={formState.status == 1}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="statusActive">
                          Active &nbsp;&nbsp;&nbsp;
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          value="0"
						  checked={formState.status != 1}
                          id="statusInactive"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="statusInactive">
                          InActive
                        </label>
                        <span className="validation-text"></span>
                      </div>
                    </div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="submit" className="btn btn-success">Add</button>
							<button type="button" onClick={() => setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default NewRole;