import axios from "axios";
import { fetchUserData } from "./usrinfo";
export const roleListAPI=async()=>{
    try {
        const config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/role`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
}

export const roleMenuListAPI = async()=>{
    try {
        const config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/role/menus`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
}

export const roleMenuForRecruiterAPI = async()=>{
  try {
      const config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/recruiter/menus`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
}

export const addNewRole = async(data)=>{
  try{
   let config =  await fetchUserData();
   await axios.post(
          `${process.env.REACT_APP_API_HOST}admin/role`,
          data,
          config
      );
  } catch (error) {
      console.error("Error on inserting logs", error);
  }   
}

export const getOneRole = async(id)=>{
  try{
   let config =  await fetchUserData();
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/role/${id}`, config);
    
    console.log("Response", response.data.data);
    return response.data.data?response.data.data[0]:{};
  } catch (error) {
      console.error("Error on inserting logs", error);
  }   
}


export const updateRole = async (data, id) => {
  try {
      let config = await fetchUserData();
      await axios.put(
          `${process.env.REACT_APP_API_HOST}admin/role/${id}`, // Ensure your URL is correct
          data,
          config
      );
  } catch (error) {
      console.error("Error on editing plan", error);
  }
}

export const deleteRole = async (id) => {
    try {
        let config = await fetchUserData();
        await axios.delete(
            `${process.env.REACT_APP_API_HOST}admin/role/${id}`,
            config
        );
        console.log("Plan deleted successfully");
    } catch (error) {
        console.error("Error on deleting plan", error);
    }
}