import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import { planListAPI, addNewPlan, editPlanAPI, editPlan, deletePlan } from "../../api/plan";
import NewPlan from "./NewPlan";
import EditPlan from "./EditPlan";
import useAccess from '../../api/useAccess';

const PlanLists = () => {
	const [addCard, setAddCard] = useState(false);
	const [plans, setPlans] = useState([]);
	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
	const { fetchAccess } = useAccess();
	const permission = fetchAccess();

	useEffect(() => {
		dataFetch();
	}, [])

	const dataFetch = async () => {
		let data = await planListAPI();
		setPlans(data);
	}

	const handleSort = (key) => {
		let direction = 'ascending';
		if (sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}

		const sortedPlans = [...plans].sort((a, b) => {
			if (a[key] < b[key]) {
				return direction === 'ascending' ? -1 : 1;
			}
			if (a[key] > b[key]) {
				return direction === 'ascending' ? 1 : -1;
			}
			return 0;
		});

		setSortConfig({ key, direction });
		setPlans(sortedPlans);
	};

	const getSortIcon = (key) => {
		if (sortConfig.key === key) {
			return sortConfig.direction === 'ascending' ? '▲' : '▼';
		}
		return '↕';
	};

	// delete data  
	const handleDeleteClick = async (contactId) => {
		const willDelete = await swal({
			title: "Are you sure?",
			text: "Are you sure that you want to delete this record?",
			icon: "warning",
			dangerMode: true,
		});

		if (willDelete) {
			await deletePlan(contactId);
			dataFetch();
			swal("Deleted!", "Record has been deleted!", "success");
		}
	}

	//Add data 
	const [addFormData, setAddFormData] = useState({
		plan_name: '',
		plan_days: '',
		plan_price: '',
		currency_code: 'INR',
		total_unlock_quota: '',
		general_unlock_quota: '',
		applicants_unlock_quota: '',
		jobs_limit: '',
		sub_recruiter_limit: '',
		created_by: '',
		published: '1'
	});

	const handleAddFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute('name');
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
	};

	const handleAddFormSubmit = async (event) => {
		event.preventDefault();
		var error = false;
		var errorMsg = '';
		if (addFormData.plan_name === "") {
			error = true;
			errorMsg = 'Please fill Plan Name';
		} else if (addFormData.jobs_limit === "") {
			error = true;
			errorMsg = 'Please fill Job Limit';
		}
		else if (addFormData.plan_days === "") {
			error = true;
			errorMsg = 'Please fill Plan Validity';
		}
		if (!error) {
			const newContact = {
				plan_name: addFormData.plan_name,
				plan_days: addFormData.plan_days,
				plan_price: addFormData.plan_price,
				currency_code: addFormData.currency_code,
				total_unlock_quota: addFormData.total_unlock_quota,
				general_unlock_quota: addFormData.general_unlock_quota,
				applicants_unlock_quota: addFormData.applicants_unlock_quota,
				jobs_limit: addFormData.jobs_limit,
				sub_recruiter_limit: addFormData.sub_recruiter_limit,
				created_by: 1,
				status: addFormData.published
			};
			await addNewPlan(newContact);
			dataFetch();
			setAddCard(false);
			swal('Successfully Added Plan!', 'Successfully Added', "success");
		} else {
			swal('Oops', errorMsg, "error");
		}
	};

	const [editModal, setEditModal] = useState(false);
	const [editContactId, setEditContactId] = useState(null);
	const fetchEditData = async (id) => {
		return await editPlanAPI(id);
	}

	const handleEditClick = async (event, contact) => {
		event.preventDefault();
		setEditContactId(contact.id);
		let formValues = await fetchEditData(contact.id);
		setEditFormData(formValues);
		setEditModal(true);
	};

	const [editFormData, setEditFormData] = useState({
		plan_name: '',
		plan_days: '',
		plan_price: '',
		currency_code: '',
		total_unlock_quota: '',
		general_unlock_quota: '',
		applicants_unlock_quota: '',
		jobs_limit: '',
		sub_recruiter_limit: '',
		created_by: '',
		published: ''
	})

	const handleEditFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute('name');
		const fieldValue = event.target.value;
		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue;
		setEditFormData(newFormData);
	};

	const handleEditFormSubmit = async (event) => {
		event.preventDefault();
		const editedContact = {
			plan_name: editFormData.plan_name,
			plan_days: editFormData.plan_days,
			plan_price: editFormData.plan_price,
			currency_code: editFormData.currency_code,
			total_unlock_quota: editFormData.total_unlock_quota,
			general_unlock_quota: editFormData.general_unlock_quota,
			applicants_unlock_quota: editFormData.applicants_unlock_quota,
			jobs_limit: editFormData.jobs_limit,
			sub_recruiter_limit: editFormData.sub_recruiter_limit,
			status: editFormData.published
		}
		await editPlan(editedContact, editContactId);
		dataFetch();
		setEditContactId(null);
		setEditModal(false);
	}

	return (
		<>
			<div className="d-flex align-items-center mb-4 flex-wrap">
				<h4 className="fs-20 font-w600  me-auto">Plan List</h4>
				<div>
					{
						permission.a === true ?
							<>
								<Link to={"#"} className="btn btn-primary me-3 btn-sm" onClick={() => setAddCard(true)}>
									<i className="fas fa-plus me-2"></i>Add New Plan
								</Link>
							</> :
							<></>
					}
					<NewPlan addCard={addCard} setAddCard={setAddCard} handleAddFormChange={handleAddFormChange} handleAddFormSubmit={handleAddFormSubmit} />
					<EditPlan editModal={editModal} setEditModal={setEditModal} handleEditFormChange={handleEditFormChange} handleEditFormSubmit={handleEditFormSubmit} editFormData={editFormData} />

				</div>
			</div>
			<div className="row">
				<div className="col-xl-12">
					<div className="table-responsive">
						<table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer" >
							<thead>
								<tr>
									<th>S.No</th>
									<th onClick={() => handleSort('plan_name')}>Plan Name {getSortIcon('plan_name')}</th>
									<th onClick={() => handleSort('plan_price')}>Plan Price {getSortIcon('plan_price')}</th>
									<th onClick={() => handleSort('plan_days')}>Validity {getSortIcon('plan_days')}</th>
									<th onClick={() => handleSort('jobs_limit')}>Jobs limit {getSortIcon('jobs_limit')}</th>
									<th onClick={() => handleSort('total_unlock_quota')}>Total Quota {getSortIcon('total_unlock_quota')}</th>
									<th onClick={() => handleSort('general_unlock_quota')}>General Quota {getSortIcon('general_unlock_quota')}</th>
									<th onClick={() => handleSort('applicants_unlock_quota')}>Applicants Quota {getSortIcon('applicants_unlock_quota')}</th>
									<th>Status</th>
									{permission.v ? <><th>Actions</th></> : <></>}
								</tr>
							</thead>
							<tbody>
								{plans.map((plan, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{plan.plan_name}</td>
										<td>{plan.plan_price}</td>
										<td>{plan.plan_days}</td>
										<td>{plan.jobs_limit}</td>
										<td>{plan.total_unlock_quota}</td>
										<td>{plan.general_unlock_quota}</td>
										<td>{plan.applicants_unlock_quota}</td>
										<td><span className={`badge badge-lg light badge-${plan.published == "1" ? 'success' : 'danger'}`}>{(plan.published == "1") ? "Active" : "InActive"}</span></td>
										{permission.v === true ? <>
											<td>
												<div className="action-buttons d-flex justify-content-end">
													{
														permission.e === true ?
															<>
																<Link to={"#"} className="btn btn-secondary light mr-2"
																	onClick={(event) => handleEditClick(event, plan)}
																>
																	<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
																		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																			<rect x="0" y="0" width="24" height="24"></rect>
																			<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
																			<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
																		</g>
																	</svg>
																</Link>
															</> : <></>
													}
													{
														permission.d === true ?
															<>
																<Link to={"#"} className="btn btn-danger light"
																	onClick={() => handleDeleteClick(plan.id)}
																>
																	<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
																		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																			<rect x="0" y="0" width="24" height="24"></rect>
																			<path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"></path>
																			<path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
																		</g>
																	</svg>
																</Link>
															</> : <></>
													}
												</div>
											</td>
										</>
											: <></>
										}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}
export default PlanLists;
