export const job_types = [{
    id: 1,
    title: "Full Time"
},
{
    id: 2,
    title: "Part Time"
},
{
    id: 3,
    title: "WFH"
},
{
    id: 4,
    title: "Intership"
}
];