import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const skillListAPI=async(params)=>{
    try {
        let config = await fetchUserData();
        const response = await axios.post(`${process.env.REACT_APP_API_HOST}admin/skill/listing`,
            params, 
            config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
        return [];
      }
}

export const editSkillAPI=async(id)=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/skill/${id}`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
}

export const addNewSkill = async(data)=>{
    try{
        let config = await fetchUserData();
     await axios.post(
            `${process.env.REACT_APP_API_HOST}admin/skill`,
            data,
            config
        );
    } catch (error) {
        console.error("Error on inserting logs", error);
    }   
}

export const editSkill = async (data, id) => {
    try {
        let config = await fetchUserData();
        await axios.put(
            `${process.env.REACT_APP_API_HOST}admin/skill/${id}`, // Ensure your URL is correct
            data,
            config
        );
    } catch (error) {
        console.error("Error on editing Skill", error);
    }
}

export const deleteSkill = async (id) => {
    try {
        let config = await fetchUserData();
        await axios.delete(
            `${process.env.REACT_APP_API_HOST}admin/skill/${id}`,
            config
        );
    } catch (error) {
        console.error("Error on deleting Skill", error);
    }
}