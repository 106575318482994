import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const sidebarAPI=async(roleid)=>{
    try {
        const config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/role/usermenus/${roleid}`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
        return false;
      }
}