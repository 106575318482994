import React,{useState, useEffect} from 'react';
import {dashboardRecuiterAPI} from "../../api/dashboard";

const DashboardRecruiter = ()=>{
    const [appl, setApp]= useState({});

    useEffect(() => {
		fetchApplicationData();
	}, []);
	
	const fetchApplicationData = async()=>{
		let d = await dashboardRecuiterAPI();
		//console.log("jobs Data", d, );
        setApp(d);
	}

    return <>
    <div className="col-xl-12">
							<div className="card">
								<div className="card-body">
								<h5 class="card-title">Recruiters</h5>
								<hr/>
									<div className="row separate-row">
										<div className="col-sm-6">
											<div className="job-icon pb-4 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.total_recruiter}</h2>
														<i className={`fa-solid ms-3 ${appl?.total_recruiter_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Total Recruiters</span>
												</div>	
												
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.active_recruiter}</h2>
														<i className={`fa-solid ms-3 ${appl?.active_recruiter_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Active Recruiter</span>
												</div>	
												
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.today_recruiter}</h2>
														<i className={`fa-solid ms-3 ${appl?.today_recruiter_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Today Recruiter</span>
												</div>	
												
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0 lh-1">{appl?.job_by_recruiter}</h2>
														<i className={`fa-solid ms-3 ${appl?.job_by_recruiter_flag}`}></i>
													</div>	
													<span className="fs-14 d-block mb-2">Jobs by recruiters</span>
												</div>	
												
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
         </>
}

export default DashboardRecruiter;