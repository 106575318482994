import React from "react";
import * as XLSX from "xlsx";

const ExperienceMatrix = (props)=>{
    const {data} = props;
	const downloadExcel = () => {
        // Prepare data for Excel export
        const worksheetData = data.map(d => ({
            "Skill Title": d.skill_title,
            "0-5 Years": d.ztof_year,
            "6-8 Years": d.ftoe_year,
            "9-12 Years": d.etot_year
        }));

        // Convert to worksheet
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);

        // Create a new workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Experience Matrix");

        // Save the file
        XLSX.writeFile(workbook, "ExperienceMatrix.xlsx");
    };

    return <>
           <div className="d-flex justify-content-between mb-3">
                <button className="btn btn-primary" onClick={downloadExcel}>
                    Download Excel
                </button>
            </div>
           <div className="row">
				<div className="col-xl-12">
					<div className="table-responsive">
						<table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer" >
							<thead>
								<tr>
									<th>Skills</th>
									<th>0-5</th>
                                    <th>6-8</th>
                                    <th>9-12</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((d, index) => (
									<tr key={index}>
										<td>{d.skill_title}</td>
										<td>{d.ztof_year}</td>
                                        <td>{d.ftoe_year}</td>
                                        <td>{d.etot_year}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>    
          </>
}

export default ExperienceMatrix;