import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { eventListAPI, deleteEvent } from "../../api/event";
import NewEvent from "./NewEvent";
import EditEvent from "./EditEvent";
import useAccess from "../../api/useAccess";
import debounce from "lodash/debounce";

const EventLists = () => {
  const [addCard, setAddCard] = useState(false);
  const { fetchAccess } = useAccess();
  const [Events, setEvents] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [filterTitle, setFilterTitle] = useState("");
  const permission = fetchAccess();
  const navigate = useNavigate();


  useEffect(() => {
    dataFetch();
  }, [sortConfig, filterTitle]);

  const dataFetch = async () => {
    const params = {
      sort_by: sortConfig.key,
      sort_direction: sortConfig.direction,
      keyword: filterTitle,
    };
    let data = await eventListAPI(params);
    setEvents(data);
  };

  // Delete data
  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      await deleteEvent(contactId);
      dataFetch();
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };

  const [editModal, setEditModal] = useState(false);
  const [editContactId, setEditContactId] = useState(null);

  const handleEditClick = async (id) => {
    setEditContactId(id);
    setEditModal(true);
  };

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <span>▲</span>;
      } else if (sortConfig.direction === "desc") {
        return <span>▼</span>;
      }
    }
    return <span>↕</span>;
  };

  const debouncedFilter = useCallback(
    debounce((value) => {
      setFilterTitle(value);
    }, 300),
    []
  );

  const handleFilterChange = (e) => {
    debouncedFilter(e.target.value);
  };

  const handleApplicantsClick = (e, event_id)=>{
    e.preventDefault();
    localStorage.setItem("event_id", event_id);
    localStorage.removeItem("job_id");
    navigate('/c_lists?type=event');
  }

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Event List</h4>
        <div className="ms-auto">
          {permission.a === true && (
            <Link
              to={"#"}
              className="btn btn-primary me-3 btn-sm"
              onClick={() => setAddCard(true)}
            >
              <i className="fas fa-plus me-2"></i>Add New Event
            </Link>
          )}
          <NewEvent
            addCard={addCard}
            setAddCard={setAddCard}
            dataFetch={dataFetch}
          />
          <EditEvent
            editModal={editModal}
            setEditModal={setEditModal}
            dataFetch={dataFetch}
            editid={editContactId}
            setEditContactId={setEditContactId}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-6 ms-auto">
          <input
            type="text"
            className="form-control"
            placeholder="Filter by Title"
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th onClick={() => sortData("title")}>
                    Title {getSortIcon("title")}
                  </th>
                  <th onClick={() => sortData("location")}>
                    Location {getSortIcon("location")}
                  </th>
                  <th onClick={() => sortData("start_date")}>
                    Start Date Time {getSortIcon("start_date")}
                  </th>
                  <th>Status</th>
                  {permission.v && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {Events?.length > 0 ? (
                  Events?.map((Event, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{Event.title}</td>
                      <td>{Event.location}</td>
                      <td>
                        {Event.start_date.split("T")[0]} {Event.start_time}
                      </td>
                      <td>
                        <span
                          className={`badge badge-lg light badge-${
                            Event.published === "1" ? "success" : "danger"
                          }`}
                        >
                          {Event.published === "1" ? "Active" : "InActive"}
                        </span>
                      </td>
                      {permission.v === true && (
                        <td>
                          <div className="d-flex justify-content-end">
                            <div>
                              <Link
                                to={"#"}
                                className="btn btn-secondary light mr-2"
                                onClick={(e) => handleApplicantsClick(e, Event.id)}
                              >
                                Applicants
                              </Link>
                            </div>
                            <div className="action-buttons d-flex justify-content-end">
                              {permission.e && (
                                <Link
                                  to={"#"}
                                  className="btn btn-secondary light mr-2"
                                  onClick={() => handleEditClick(Event.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                    className="svg-main-icon"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                      ></path>
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="5"
                                        y="20"
                                        width="15"
                                        height="2"
                                        rx="1"
                                      ></rect>
                                    </g>
                                  </svg>
                                </Link>
                              )}
                              {permission.d && (
                                <Link
                                  to={"#"}
                                  className="btn btn-danger light"
                                  onClick={() => handleDeleteClick(Event.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                    className="svg-main-icon"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                      ></path>
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      ></path>
                                    </g>
                                  </svg>
                                </Link>
                              )}
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="100%" className="text-center">
                        {" "}
                        No Record Found.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventLists;
