import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const skillsAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/matrix/skills`, config);
        return response.data
      } catch (error) {
        console.error("Error fetching skills matrix items:", error);
      }
}

export const locationsAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/matrix/locations`, config);
        return response.data
      } catch (error) {
        console.error("Error fetching locations matrix items:", error);
      }
}

export const exprienceAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/matrix/experiences`, config);
        return response.data
      } catch (error) {
        console.error("Error fetching experiences matrix items:", error);
      }
}

export const identityAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/matrix/identifies`, config);
        return response.data
      } catch (error) {
        console.error("Error fetching identifies matrix items:", error);
      }
}


export const reportingSkills=async()=>{
  try {
      let config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/reporting/skills/tech`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching identifies matrix items:", error);
    }
}

export const reportingNonTechSkills=async()=>{
  try {
      let config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/reporting/skills/nontech`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching identifies matrix items:", error);
    }
}

export const reportingLocations=async()=>{
  try {
      let config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/reporting/locations`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching identifies matrix items:", error);
    }
}

export const reportingExperiences=async()=>{
  try {
      let config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/reporting/experiences`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching identifies matrix items:", error);
    }
}

export const reportingIdentify=async()=>{
  try {
      let config = await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/reporting/identify`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching identifies matrix items:", error);
    }
}


