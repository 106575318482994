import axios from "axios";
import { fetchUserData, fetchUserDataWithMutipart } from "./usrinfo";

export const uploadImage = async(data)=>{
    try{
     let config =  await fetchUserDataWithMutipart();
     let res = await axios.post(
            `${process.env.REACT_APP_API_HOST}admin/upload`,
            data,
            config
        );
     return res.data;
    } catch (error) {
        console.error("Error on inserting logs", error);
        return false;
    }   
}

export const getAllSkills=async()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}get/skills`);
        return response.data.data
      } catch (error) {
        console.error("Error fetching skills items:", error);
      }
}

export const getAllIdentity=async()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}get/identity`);
        return response.data.data
      } catch (error) {
        console.error("Error fetching skills items:", error);
      }
}

export const getAllCities=async()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}get/cities`);
        return response.data.data
      } catch (error) {
        console.error("Error fetching skills items:", error);
      }
}


export const getSetting=async()=>{
  try {
    let config =  await fetchUserData();
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/user/setting/1`, config);
      return response.data.data
    } catch (error) {
      console.error("Error fetching skills items:", error);
    }
}

export const updateSetting = async (id, data) => {
  try {
      let config = await fetchUserData();
      await axios.put(
          `${process.env.REACT_APP_API_HOST}admin/user/setting/${id}`, // Ensure your URL is correct
          data,
          config
      );
  } catch (error) {
      console.error("Error on editing cms", error);
      return false;
  }
}





