import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { editUserAPI, editUser } from "../../api/user";
import swal from "sweetalert";
const initialState={
	name: '',
	email: '',
	password: '',
	repassword:'',
	role_id: '',
	created_by: '',
	published: ''
}

const EditUser = (props) => {
	const { editModal, setEditModal, menuData, dataFetch, editid, setEditContactId } = props;
	const [updatePassword, setUpdatePassword] = useState(false);
	const [editFormData, setEditFormData] = useState(initialState)

	useEffect(() => {
		if(editid>0){
			updateFormData();
     	}
	}, [editid])

	const updateFormData = async () => {
		let formValues = await editUserAPI(editid);
		formValues.password="";
		formValues.repassword="";
		setEditFormData(formValues);
	}

	//update data function
	const handleEditFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute('name');
		const fieldValue = event.target.value;
		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue;
		setEditFormData(newFormData);
	};

	// edit form data submit
	const handleEditFormSubmit = async (event) => {
		event.preventDefault();
		let editedContact = {};
		var error = false;
		var errorMsg = '';
        if(editFormData.name === ""){
            error = true;
			errorMsg = 'Please fill User Name';
        }else if(editFormData.email === ""){
            error = true;
			errorMsg = 'Please fill User Email';
        }
        else if(editFormData.password === "" && updatePassword==true){
            error = true;
			errorMsg = 'Please fill Password';
        }
		else if(editFormData.repassword === "" && updatePassword==true){
            error = true;
			errorMsg = 'Please fill Confirm Password';
        } 
		else if(editFormData.password != editFormData.repassword && updatePassword==true){
            error = true;
			errorMsg = 'Confirm password is not match with password';
        } 
		else if(editFormData.role_id == ""){
            error = true;
			errorMsg = 'Please select Role';
        } 
        if(!error){
			if(updatePassword==true){
			editedContact = {
				name: editFormData.name,
				email: editFormData.email,
				password: editFormData.password,
				role_id: editFormData.role_id,
				status: editFormData.published
			}
		}else{
			editedContact = {
				name: editFormData.name,
				email: editFormData.email,
				role_id: editFormData.role_id,
				status: editFormData.published
			}
		}
			await editUser(editedContact, editid);
			dataFetch();
			setEditContactId(null);
			setEditFormData(initialState);
			setUpdatePassword(false);
			setEditModal(false);
			swal('Successfully Updated User!', 'Successfully Updated', "success");
	}else{
		swal('Oops', errorMsg, "error");
	}

	}
	return (
		<>
			<Modal className="modal fade bd-example-modal-lg" show={editModal} onHide={setEditModal} centered size="lg">
				<div role="document">
					<div className="">
						<form>
							<div className="modal-header">
								<h4 className="modal-title fs-20">Update User</h4>
								<button type="button" className="btn-close" onClick={() => setEditModal(false)} data-dismiss="modal">
									<span></span>
								</button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="form-group mb-6">
											<div className="row">
												<div className="form-group mb-6 col-md-12">
													<label className="text-black font-w500 mt-2">Username <span className="text-danger">*</span> </label>
													<div className="contact-name">
														<input
															type="text"
															className="form-control"
															autoComplete="off"
															name="name"
															required="required"
															value={editFormData?.name}
															onChange={handleEditFormChange}
															placeholder="Enter User Name"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>

										<div className="form-group mb-6">
											<div className="row">
												<div className="form-group mb-6 col-md-12">
													<label className="text-black font-w500 mt-2">User Email <span className="text-danger">*</span></label>
													<div className="contact-name">
														<input
															type="text"
															className="form-control"
															autoComplete="off"
															name="email"
															value={editFormData?.email}
															required="required"
															onChange={handleEditFormChange}
															placeholder="Enter User Email Address"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>
										<div className="form-group mb-6">
										<label className="text-black font-w500 mt-2">Do you want to change Password?</label>
										<div className="contact-name">
											<div className="form-group mb-0">
												<label className="radio-inline me-3">
													<input type="radio" name="optradio" checked={updatePassword === true} onClick={()=>setUpdatePassword(true)}/> Yes
													</label>
													<label className="radio-inline me-3">
														<input type="radio" name="optradio" checked={updatePassword === false}  onClick={()=>setUpdatePassword(false)}/> No
													</label>
										   </div>
										   </div>
										</div>
                                        { updatePassword?
										<>
										<div className="form-group mb-6">
											<div className="row">
												<div className="form-group mb-6 col-md-12">
													<label className="text-black font-w500 mt-2">Password <span className="text-danger">*</span></label>
													<div className="contact-name">
														<input
															type="password"
															className="form-control"
															autoComplete="off"
															name="password"
															required="required"
															onChange={handleEditFormChange}
															placeholder="Enter Password"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>

										<div className="form-group mb-6">
											<div className="row">
												<div className="form-group mb-6 col-md-12">
													<label className="text-black font-w500 mt-2">Confirm Password <span className="text-danger">*</span></label>
													<div className="contact-name">
														<input
															type="password"
															className="form-control"
															autoComplete="off"
															name="repassword"
															required="required"
															onChange={handleEditFormChange}
															placeholder="Enter Confirm Password"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>
                                        </>
										:
										<></>
										}
										<div className="form-group mb-6">
											<div className="row">
												<div className="form-group mb-6 col-md-12">
													<label className="text-black font-w500 mt-2">Select Role <span className="text-danger">*</span></label>
													<div className="contact-name">
														<select
															className="form-select"
															id="role_id"
															name="role_id"
															onChange={handleEditFormChange}
															value={editFormData?.role_id}
														>
															<option value="">Please select</option>
															{menuData?.map((item, index) => {
																return <><option value={item.id}>{item.role_name}</option></>;
															})}
														</select>

														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>


										<div className="form-group mb-3">
											<label className="text-black font-w500 mt-2">Status</label>
											<div className="contact-name">
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="1"
													id="statusActive"
													checked={editFormData?.published == "1"}
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusActive">
													Active
												</label>
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="0"
													checked={editFormData?.published == "0"}
													id="statusInactive"
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusInactive">
													InActive
												</label>
												<span className="validation-text"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-success" onClick={handleEditFormSubmit}>
									Update
								</button>
								<button type="button" onClick={() => setEditModal(false)} className="btn btn-danger">
									<i className="flaticon-delete-1"></i> Discard
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	)
}
export default EditUser;