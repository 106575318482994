import axios from "axios";
import { fetchUserData } from "./usrinfo";
export const recruiterListAPI=async(params)=>{
    try {
        let config = await fetchUserData();
        const response = await axios.post(`${process.env.REACT_APP_API_HOST}admin/recruiter/listing`, 
            params,
            config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
        return false;
      }
}

export const subRecruiterListAPI=async(params)=>{
    try {
        let config = await fetchUserData();
        const response = await axios.post(`${process.env.REACT_APP_API_HOST}sub-recruiter/list`, 
            params,
            config);
            console.log("response",response)
        return response.data.data
      } catch (error) {
        console.error("Error fetching menu items:", error);
        return false;
      }
}

export const EditRAPI=async(id)=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/recruiter/${id}`,config);
        return response.data.data
       // const initialState = {"plan_id":"4","name":"Pankaj","company_name":"aspagteq","email":"sachin89tyagi@gmail.com","recruiter_type":"1","menus":[3,4,12,1],"publish":"0"}
        // return initialState;
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
}

export const addNewR = async(data)=>{
    try{
     let config = await fetchUserData();
     let res = await axios.post(
            `${process.env.REACT_APP_API_HOST}admin/recruiter`,
            data,
            config
        );
     return res.data;
    } catch (error) {
        console.error("Error on inserting logs", error);
        return false;
    }   
}

export const EditRecruiter = async (data, id) => {
    try {
        let config = await fetchUserData();
       let res =  await axios.put(
            `${process.env.REACT_APP_API_HOST}admin/recruiter/${id}`, // Ensure your URL is correct
            data,
            config
        );
        return res.data;
    } catch (error) {
        console.error("Error on editing Event", error);
        return false;
    }
}

export const deleteRecruiter = async (id) => {
    try {
        let config = await fetchUserData();
        let res = await axios.delete(
            `${process.env.REACT_APP_API_HOST}admin/recruiter/${id}`,
            config
        );
        return res.data;
    } catch (error) {
        console.error("Error on deleting Event", error);
    }
}


export const getUnlock = async(data)=> {
    try{
        let config = await fetchUserData();
        let res = await axios.post(
               `${process.env.REACT_APP_API_HOST}sub-recruiter/report/unlock-candidate-lists`,
               data,
               config
           );
        return res;
       } catch (error) {
           console.error("Error on get unlock logs", error);
           return false;
       } 
  }


  export const getDownlodedResume = async(data)=> {
    try{
        let config = await fetchUserData();
        let res = await axios.post(
               `${process.env.REACT_APP_API_HOST}sub-recruiter/downloaded-resume`,
               data,
               config
           );
        return res;
       } catch (error) {
           console.error("Error on getDownlodedResume logs", error);
           return false;
       } 
  }


  export const getTimeLog = async(data)=> {
    try{
        let config = await fetchUserData();
        let res = await axios.post(
               `${process.env.REACT_APP_API_HOST}sub-recruiter/login-logout-duration`,
               data,
               config
           );
        return res;
       } catch (error) {
           console.error("Error on getTimeLog logs", error);
           return false;
       } 
  }
  
  
 
  
  export const getProfileView = async(data)=> {
    try{
        let config = await fetchUserData();
        let res = await axios.post(
               `${process.env.REACT_APP_API_HOST}sub-recruiter/checkout-candidates`,
               data,
               config
           );
        return res;
       } catch (error) {
           console.error("Error on getProfileView logs", error);
           return false;
       } 
  }
  