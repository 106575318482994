import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const cmsListAPI=async()=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/cms`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching cms items:", error);
        return false;
      }
}

export const editCMSAPI=async(id)=>{
    try {
        let config = await fetchUserData();
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/cms/${id}`, config);
        return response.data.data
      } catch (error) {
        console.error("Error fetching cms items:", error);
      }
}


export const editCMS = async (data, id) => {
    try {
        let config = await fetchUserData();
        await axios.put(
            `${process.env.REACT_APP_API_HOST}admin/cms/${id}`, // Ensure your URL is correct
            data,
            config
        );
    } catch (error) {
        console.error("Error on editing cms", error);
    }
}
