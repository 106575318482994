import React,{useState, useEffect } from "react";
import { getAllIdentity } from "../../api/common";
import * as XLSX from "xlsx";

const IdentityMatrix = (props)=>{
    const {data} = props;
    const [identity, setIdentity] = useState([]);

    useEffect(()=>{
        fetchData();
    }, [])

    const fetchData=async()=>{
        let da = await getAllIdentity();
        setIdentity(da);
    }

    const downloadExcel = () => {
        // Prepare the data for Excel export
        const worksheetData = data.map(d => {
            const row = { Skill: d.skill_title };
            d.identifies.forEach((identityItem, index) => {
                row[identity[index]?.identify_name] = identityItem.candidates;
            });
            return row;
        });

        // Convert to worksheet
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);

        // Create a new workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Identity Matrix");

        // Save the file
        XLSX.writeFile(workbook, "IdentityMatrix.xlsx");
    };

    return <>
           <div className="d-flex justify-content-between mb-3">
                <button className="btn btn-primary" onClick={downloadExcel}>
                    Download Excel
                </button>
            </div>
           <div className="row">
				<div className="col-xl-12">
					<div className="table-responsive">
						<table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer" >
							<thead>
								<tr>
									<th>Skill</th>
                                    {
                                      identity?.map((item)=>{
                                        return <><th>{item.identify_name}</th></>
                                      })     
                                    }
								</tr>
							</thead>
							<tbody>
								{data?.map((d, index) => (
									<tr key={index}>
										<td>{d.skill_title}</td>
                                        {
                                            d?.identifies?.map((item)=>{
                                                return <><td>{item.candidates}</td></>
                                            })
                                        }
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>    
          </>
}

export default IdentityMatrix;