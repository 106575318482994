import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import { Link, useNavigate } from 'react-router-dom';
import CkEditorBlog from '../Forms/CkEditor/CkEditorBlog';
import { uploadImage } from "../../api/common";
import { editCMSAPI, editCMS } from "../../api/cms";

const EditList = () => {
    const [data, setData] = useState({
        heading: '',
        content: '',
        image: '',
        alt: ''
    });
    const navigate = useNavigate();
    const cId = localStorage.getItem('nid');
    useEffect(() => {
        dataFetch();
    }, []);

    const dataFetch = async () => {
        let data = await editCMSAPI(cId);
        setData(data);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
		const formData = new FormData();
        if (file) {
            formData.append('file', file);
            const response = await uploadImage(formData);
            if (response!=false) {
                setData({ ...data, image: response.data });
                //swal("Success", "Image uploaded successfully", "success");
            } else {
                swal("Error", "Image upload failed", "error");
            }
        }
    };

    const updateEditor = (value) => {
        setData({ ...data, content: value });
    };

    const handleUpdateCMS = async () => {
        await editCMS(data, cId);
        swal('Successfully Updated CMS!', 'Successfully CMS', "success");
        navigate('/cms');
    };

    return (
        <>
            <div className="d-flex align-items-center mb-4 flex-wrap">
                <h4 className="fs-20 font-w600 me-auto">Update CMS</h4>
                <Link to="/cms" className="btn btn-primary me-3 btn-sm">
                        <i className="fas fa-plus me-2"></i>Back to Listing
                </Link>
            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Heading</h4>
                        </div>
                        <div className="card-body">
                            <input
                                type="text"
                                className="form-control"
                                name="heading"
                                value={data.heading}
                                onChange={handleInputChange}
                                placeholder="Heading"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Content</h4>
                        </div>
                        <div className="card-body custom-ekeditor">
                            <CkEditorBlog data={data.content} updateEditor={updateEditor} />
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Image</h4>
                        </div>
                        <div className="card-body">
                            <input
                                type="file"
                                className="form-control"
                                onChange={handleImageUpload}
                            />
                            {data.image && <img src={`path/to/your/image/directory/${data.image}`} alt={data.alt} className="mt-2" style={{ width: '100px' }} />}
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Alt Text</h4>
                        </div>
                        <div className="card-body">
                            <input
                                type="text"
                                className="form-control"
                                name="alt"
                                value={data.alt}
                                onChange={handleInputChange}
                                placeholder="Alt Text"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <button type="submit" className="btn btn-primary" onClick={handleUpdateCMS}>Update</button>
            </div>
        </>
    );
};

export default EditList;